import AxiosService from '@/services/AxiosService'

export default {
  /**
   * Get shops with export data
   * @returns {*}
   */
  postShopsExportAdmin(data)
  {
    return AxiosService.callApi().post('/export/admin/csv', data)
  },
  /**
   * get shops with export data for marketing
   *
   * @returns {*}
   */
  postShopsExportMarketing(data)
  {
    return AxiosService.callApi().post('/export/marketing', data)
  },
  /**
   *
   * @param data
   * @returns {*}
   */
  getHtmlExportAdminCats(data)
  {
    return AxiosService.callApi().post('/export/admin/html/categories', data)
  },
  /**
   * Send new html to API
   *
   * @param data
   */
  sendHtmlExportAdminCats(data)
  {
    return AxiosService.callApi().post('/export/response/admin/html/categories', data)
  },
  /**
   *
   * @param data
   * @returns {*}
   */
  getHtmlExportAdminShops(data)
  {
    return AxiosService.callApi().post('/export/admin/html/shops', data)
  },
  /**
   * Send new html to API
   *
   * @param data
   */
  sendHtmlExportAdminShops(data)
  {
    return AxiosService.callApi().post('/export/response/admin/html/shops', data)
  },
  /**
   *
   * @param data
   * @returns {*}
   */
  getHtmlExportAdminStats(data)
  {
    return AxiosService.callApi().post('/export/admin/html/stats', data)
  },
  /**
   * Send new html to API
   *
   * @param data
   */
  sendHtmlExportAdminStats(data)
  {
    return AxiosService.callApi().post('/export/response/admin/html/stats', data)
  },
}
