import AxiosService from '@/services/AxiosService'
import UrlBuilder from "@/helper/urlBuilder";

export default {
  /**
   * Get list of shop
   *
   * @param centerId => current center
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @returns {*}
   */
  getShops(centerId, perPage, page, order, search) {
    return AxiosService.callApi().get(UrlBuilder('shops', centerId, {
      perPage : perPage,
      page: page,
      order: order,
      search: search
    }))
  },
  /**
   * Get shop by id
   *
   * @param centerId
   * @param id
   * @returns {*}
   */
  getShop(centerId, id) {
    return AxiosService.callApi().get('/' + centerId + '/shops/' + id)
  },
  /**
   * Create shop
   *
   * @param centerId
   * @param data
   * @returns {*}
   */
  create(centerId, data) {
    return AxiosService.callApi().post('/' + centerId + '/shops', data)
  },
  /**
   * Edit shop
   *
   * @param centerId
   * @param id
   * @param data
   * @returns {*}
   */
  edit(centerId, id, data) {
    return AxiosService.callApi().put('/' + centerId + '/shops/' + id, data)
  }
}
