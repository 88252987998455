import AxiosService from '@/services/AxiosService'

export default {
  /**
   * Get total sales full year on shop
   * @param model
   */
  getTotalSales(model)
  {
    return AxiosService.callApi().get('/' + model.centerId + '/sale/certified/totalSales/' + model.shopId + '/' + model.year)
  },
  /**
   * Get sale Certified
   *
   * @returns {*}
   */
  getSaleCertified(model)
  {
    return AxiosService.callApi().get('/' + model.centerId + '/sale/certified/' + model.shopId + '/' + model.year)
  },
  /**
   * Create sale Certified
   *
   * @param model
   * @returns {*}
   */
  create(model)
  {
    return AxiosService.callApi().post('/' + model.centerId + '/sale/certified', model.saleCertified)
  },
  /**
   * Edit sale Certified
   *
   * @param model
   * @returns {*}
   */
  edit(model)
  {
    return AxiosService.callApi().put('/' + model.centerId + '/sale/certified/' + model.saleCertified.shopId, model.saleCertified)
  },
}
