import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// MODULE
import ChannelModule from '@/store/modules/ChannelModule'
import AuthModule from '@/store/modules/AuthModule'
import CenterModule from '@/store/modules/CenterModule'
import ShopModule from '@/store/modules/ShopModule'
import ExportModule from '@/store/modules/ExportModule'
import DashboardModule from '@/store/modules/DashboardModule'
import ShopEventModule from '@/store/modules/ShopEventModule'
import UserModule from '@/store/modules/UserModule'
import CategoryModule from '@/store/modules/CategoryModule'
import CompanyModule from '@/store/modules/CompanyModule'
import SaleModule from '@/store/modules/SaleModule'
import SaleRatingModule from '@/store/modules/SaleRatingModule'
import SaleCertifiedModule from '@/store/modules/SaleCertifiedModule'
import EventModule from '@/store/modules/EventModule'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    ChannelModule,
    AuthModule,
    CenterModule,
    ShopModule,
    ExportModule,
    DashboardModule,
    ShopEventModule,
    UserModule,
    CategoryModule,
    CompanyModule,
    SaleModule,
    SaleRatingModule,
    SaleCertifiedModule,
    EventModule
  }
})
