import ExportService from "@/services/ExportService";

export default {
  state: {
    csvUrlAdmin: null,
    csvUrlMarketing: null,
    total: null,
    totalRequest: null
  },
  mutations: {
    SET_CSV_URL_ADMIN(state, csvUrlAdmin) {
      state.csvUrlAdmin = csvUrlAdmin
    },
    SET_CSV_URL_MARKETING(state, csvUrlMarketing)
    {
      state.csvUrlMarketing = csvUrlMarketing
    }
  },
  actions: {
    /**
     * Fetch in state list of shops export
     *
     * @param commit
     * @param model
     * @returns {*}
     */
    csvExportAdmin({ commit }, model) {
      return ExportService.postShopsExportAdmin(model)
        .then(response => {
          commit('SET_CSV_URL_ADMIN', response.data)

          return Promise.resolve(response.data)
        })
        .catch(error => {
          console.log('There was an error on export admin CSV : ', error)
        })
    },
    /**
     * Call API for generate and download CSV FILE
     *
     * @param commit
     * @param model
     * @returns {*}
     */
    csvExportMarketing({ commit }, model) {
      return ExportService.postShopsExportMarketing(model)
        .then(response => {
          commit('SET_CSV_URL_MARKETING', response.data)

          return Promise.resolve(response.data)
        })
        .catch(error => {
          console.log('There was an error on export marketing: ', error)
        })
    },
  },
}
