<template>
  <div class="row h-100" id="page-create-user-password">

    <div v-if="!isErrors && !formSend" class="col-4 m-auto">
      <h2 class="text-center">Bonjour, {{ UserModule.tempUser.firstname }} {{ UserModule.tempUser.lastname }}</h2>
      <p class="text-center">Merci de renseigner votre nouveau mot de passe</p>

      <form @submit.prevent="editPassword">
        <div class="form-group">
          <input class="form-control" id="password" v-model="user.password" type="password" :placeholder="placeholderInput">
        </div>

        <input type="submit" class="btn btn-lg btn-block btn-success" :value="valueButtonSubmit" />
      </form>
    </div>
    <div v-else-if="isErrors" class="col-4 m-auto text-center">
      <p v-if="errors.error_wrong_user">{{ errors.error_wrong_user }}</p>
      <p v-if="errors.error_token">{{ errors.error_token }}</p>
      <p>Merci de contacter votre administrateur</p>
    </div>
    <div class="col-4 m-auto text-center" v-else-if="formSend">
      <div class="text-center mt-2">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      isErrors: '',
      formSend: false,
      valueButtonSubmit: 'Changer mon mot de passe',
      placeholderInput: 'Entrer votre mot de passe',
      user: {
        id: '',
        password: '',
        token: this.$route.params.token
      }
    }
  },
  created() {
    this.$store.dispatch('getPublicUser', {
      token : this.$route.params.token,
      id: this.$route.params.id
    })
      .then(() => {
          this.isErrors = false
        })
      .catch((error) => {
        this.errors = error
        this.isErrors = true
    })
  },
  methods: {
    /**
     * Update user (submit form)
     */
    editPassword() {
      this.$store.dispatch('updatePassword', {
        token: this.$route.params.token,
        id: this.$route.params.id,
        data: this.user
      })
        .then((response) => {
          if(response) {
            if(response.error_token) {
              this.$bvToast.toast(response.error_token, {
                title: 'ERREUR !',
                variant: 'danger',
                solid: true
              })
            } else if (response.error_wrong_user) {
              this.$bvToast.toast(response.error_wrong_user, {
                title: 'ERREUR !',
                variant: 'danger',
                solid: true
              })
            }
          } else {
            this.$bvToast.toast('Vous allez être redirigé vers la page de connexion', {
              title: 'Votre nouveaux mot de passe à été changé',
              variant: 'success',
              solid: true
            })

            this.formSend = true

            this.redirectAndRefresh()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    /**
     * Redirect to user-list after submit
     * Refresh ErrorsForm
     */
    redirectAndRefresh()
    {
      // Redirect
      setTimeout( () => this.$router.push({ path: '/login'}), 5000);
    }
  },
  computed: {
    ...mapState(['UserModule'])
  }
}
</script>

<style scoped>
</style>
