import ShopService from '@/services/ShopService'

export default {
  state: {
    shop: {},
    shops: [],
    total: null,
    totalRequest: null
  },
  mutations: {
    ADD_SHOP(state, shop) {
      state.shops.push(shop)
    },
    SET_SHOPS(state, shops) {
      state.shops = shops
    },
    SET_SHOPS_TOTAL(state, total) {
      state.total = total
    },
    SET_SHOPS_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_SHOP(state, shop) {
      state.shop = shop
    }
  },
  actions: {
    /**
     * Fetch in state list of shops
     *
     * @param commit
     * @param centerId
     * @param perPage
     * @param page
     * @param order
     * @param search
     */
    fetchShops({ commit }, { centerId, perPage, page, order, search }) {
      return ShopService.getShops(
        centerId,
        (perPage) ? perPage: null,
        (page) ? page: null,
        (order) ? order : null,
        (search) ? search : null)
        .then(response => {
          commit('SET_SHOPS', response.data.list)
          commit('SET_SHOPS_TOTAL', response.data.total)
          commit('SET_SHOPS_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on shop list: ', error)
        })
    },
    /**
     * Fetch in state a shop
     *
     * @param commit
     * @param getters
     * @param model
     */
    fetchShop({ commit }, model)
    {
      ShopService.getShop(model.centerId, model.shopId)
        .then(response => {
          commit('SET_SHOP', response.data)
        })
        .catch((error) => {
          console.log('There was an error on shop: ', error)
        })
    },
    /**
     * Create shop
     *
     * @param commit
     * @param model
     */
    createShop({ commit }, model)
    {
      return ShopService.create(model.centerId, model.shop)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_SHOP', model.shop)
          return Promise.resolve()
        })
    },
    /**
     * Update shop
     *
     * @param commit
     * @param model
     * @returns {*}
     */
    updateShop({ commit }, model)
    {
      return ShopService.edit(model.centerId, model.shop.id, model.shop)
        .then((response) => {
          if(response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('SET_SHOP', response.data)
        })
    }
  },
  getters: {
    /**
     * Get shop by id in list shops
     *
     * @param state
     * @returns {function(*): *}
     */
    getShopById: state => id => {
      return state.shops.find(shop => shop.id === id)
    }
  }
}
