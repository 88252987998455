/**
 * builder url for api call
 * @param originUrl
 * @param centerId
 * @param model
 * @returns {string}
 */
export default function builderUrl(originUrl, centerId, model) {
  let url = (centerId) ? '/' + centerId + '/' + originUrl : '/' + originUrl

  if(model.perPage)
    url += '?_limit=' + model.perPage + '&_page=' + model.page
  if(model.order)
    url += '&_index=' + model.order.index + '&_order=' + model.order.order
  if(model.search)
    url += '&_search=' + model.search
  if(model.date)
    url += '?_month=' + model.date.month + '&_year=' + model.date.year

  return url
}
