<template>
  <div class="row h-100" id="page-login">
    <div class="col-12 col-md-12 col-xl-6 block-logo-login d-flex justify-content-center align-items-center position-relative">
      <div class="background h-100 w-100"></div>
      <img :src="require('../../../public/images/'+ ChannelModule.channel.code +'/logo.svg')" alt="Logo">
    </div>
    <div class="col-12 col-md-12 col-xl-6 block-login d-flex justify-content-center align-items-center">
      <div class="col-12 col-md-6 text-center d-flex flex-column justify-content-center">
        <div class="icon-user-login m-auto">
          <b-icon icon="person" font-scale="1.5" variant="primary"></b-icon>
        </div>

        <h4 class="mt-5 mb-5 mt-md-5 mb-md-5">
          Bienvenue sur votre espace en ligne {{ ChannelModule.channel.name }}
        </h4>

        <div v-if="isAuthError" v-on:click="clickErrorForm" class="alert alert-danger" role="alert">
          {{ authError }}
        </div>

        <div class="col-12 col-md-9 m-auto" >
          <form @submit.prevent="tryToLogin">
            <div class="form-group">
              <input v-model="username" class="form-control" type="text" name="email" id="email" value placeholder="Identifiant"/>
            </div>

            <div class="form-group">
              <input v-model="password" class="form-control" type="password" name="password" id="password" value placeholder="Mot de passe"/>
            </div>

            <div>
              <router-link class="nav-link p-0" :to="{ name: 'forgot-password' }">Mot de passe oublié ?</router-link>
            </div>

            <div class="text-center mt-2" v-if="isFormOk">
              <b-spinner variant="success" label="Spinning"></b-spinner>
              <div class="text-primary">Connexion en cours ...</div>
            </div>

            <button class="mt-3 mt-md-3 btn btn-primary" type="submit" name="button">
              Se connecter
            </button>
          </form>
        </div>

        <div class="mt-5 mb-2 mt-md-5 footer-login text-grey small-text">
          Socri REIM | {{ ChannelModule.channel.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      page: {
        background: '../../../assets/images/background.jpg'
      },
      username: '',
      password: '',
      authError: null,
      isAuthError: false,
      isFormOk: false
    }
  },
  methods: {
    clickErrorForm() {
      this.isAuthError = false
    },
    tryToLogin() {
      this.isFormOk = true

      this.$store
        .dispatch('login', {
          centerId: this.$store.getters.getChannel.center.id,
          username: this.username,
          password: this.password
        })
        .then(() => {
          this.isAuthError = false

          // Redirect
          if(this.$store.getters.userIsAdmin) {
            this.$router.push({ name: 'dashboard' })
          } else if (this.$store.getters.userIsMarketing) {
            this.$router.push({ name: 'export-marketing-index' })
          } else {
            this.$router.push({ name: 'sale', params: {id: this.AuthModule.currentUser.shop.id} })
          }
        }).catch((error) => {
          if(error.message === 'Invalid credentials.') {
            this.authError = 'Erreur sur le mot de passe ou le nom d\'utilisateur, merci de vérifier vos idendifiants'
          } else if (error.status === 403) {
            this.authError = 'Vous n\'avez pas accès à ce centre !'
          } else {
            this.authError = error.message ? error.message : ''
          }

          this.isAuthError = true
          this.isFormOk = false
        })
    },
  },
  computed: {
    ...mapState(['ChannelModule']),
    ...mapState(['AuthModule']),
  }
}
</script>

<style scoped>
.alert {
  cursor: pointer;
}
</style>
