<template>
  <div id="page-shop-create">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-4">
      <h5 class="mb-5">{{ dataPage.title }}</h5>
      <div>
        <router-link
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'shop-list' }"
        >
          retour
        </router-link>
      </div>
    </div>

    {{ /* Form component */ }}
    <FormCrudShop
        :shop="shop"
        :actionForm="'create'"
    />
  </div>
</template>

<script>

export default {
  data() {
    return {
      dataPage: {
        title: 'Créer un commerce'
      },
      shop: {
        isMonth: false,
        doubleSaleEntry: false,
        isHaveSale: true,
      }
    }
  },
  components : {
    FormCrudShop: () => import('@/components/pages/shop/FormCrudShop')
  }
}
</script>

<style scoped>

</style>
