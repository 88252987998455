import AxiosService from '@/services/AxiosService'
import UrlBuilder from "@/helper/urlBuilder";

export default {
  /**
   * Get list of company
   *
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @returns {*}
   */
  getCompanies(perPage, page, order, search) {
    return AxiosService.callApi().get(UrlBuilder('companies', null, {
      perPage : perPage,
      page: page,
      order: order,
      search: search
    }))
  },
  /**
   * Get company by id
   *
   * @param id
   * @returns {*}
   */
  getCompany(id) {
    return AxiosService.callApi().get('/companies/' + id)
  },
  /**
   * Create company
   *
   * @param data
   * @returns {*}
   */
  create(data) {
    return AxiosService.callApi().post('/companies', data)
  },
  /**
   * Edit company
   *
   * @param id
   * @param data
   * @returns {*}
   */
  edit(id, data) {
    return AxiosService.callApi().put('/companies/' + id, data)
  }
}
