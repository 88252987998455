<template>
  <div id="page-user-edit">
    <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
      <div class="text-center">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
    <div v-else>
      {{ /* Heading page */ }}
      <div class="header-pages-block d-md-flex justify-content-between mb-5">
        <div>
          <h5>{{ dataPage.title }} <strong>{{ UserModule.user.firstname }}  {{ UserModule.user.lastname }}</strong></h5>
          <h5>Commerce : <strong>{{ (UserModule.user.shop) ? UserModule.user.shop.name : '' }}</strong></h5>
        </div>
        <div>
          <router-link
              class="btn btn-outline-primary mt-2 mt-md-0"
              :to="{ name: 'user-list' }"
          >
            retour
          </router-link>
        </div>
      </div>

      {{ /* Form component */ }}
      <FormCrudUser
          :user="UserModule.user"
          :id="UserModule.user.id"
          :actionForm="'edit'"
          :selected-shop="getSelectedShop"
          from="crud"
      />
    </div>

  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      loader: {
        isLoading: false
      },
      dataPage: {
        title: 'Edition de l\'utilisateur : ',
      }
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  async created() {
    this.showLoader()

    let model = {
      centerId: this.$store.getters.getChannel.center.id,
      userId: this.$route.params.id
    }

    await this.$store.dispatch('fetchUser', model)
      .then(() => {
        setTimeout(function () { this.hideLoader() }.bind(this), 250)
      })
  },
  computed: {
    /**
     * Set var selectedShop
     *
     * @returns {*|number}
     */
    getSelectedShop()
    {
      return (this.UserModule.user && this.UserModule.user.shop) ? this.UserModule.user.shop.id : 0
    },
    ...mapState(['ChannelModule']),
    ...mapState(['UserModule'])
  },
  components: {
    FormCrudUser: () => import('@/components/pages/user/FormCrudUser')
  }
}
</script>

<style scoped>

</style>
