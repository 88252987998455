<template>
  <div id="page-sale-certified">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-4">
      <div>
        <h5>{{ dataPage.title }}</h5>
        <h5>Commerce : <strong>{{ (ShopModule.shop) ? ShopModule.shop.name : '' }}</strong></h5>
      </div>
      <div>
        <router-link
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'shop-list' }"
        >
          retour
        </router-link>
      </div>
    </div>

    {{ /* CARD */ }}
    <div class="row">
      <div class="col-12">
        <div class="card app-box-shadow">
          <div class="card-body">
            {{ /* DATEPICKER */ }}
            <div>
              <div class="mr-2 mt-2 mt-md-0 mr-md-0">Choisir une année</div>
              <Datepicker
                  @selected="clickDatePicker"
                  class="mt-1 mr-2 mr-md-0"
                  id="date-sale"
                  :format="formatDatepicker"
                  :minimumView="'year'"
                  :maximumView="'year'"
                  :value="datepicker.defaultDate"
                  :disabledDates="datepicker.disabledDates"
                  input-class="text-right"
              ></Datepicker>
            </div>
            {{ /* FORM */ }}
            <div class="mt-2" v-if="datepicker.year">

              <h4 class="mr-2 mt-3 mb-3">CA certifiés</h4>
              <hr />

              <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
                <div class="text-center">
                  <b-spinner variant="success" label="Spinning"></b-spinner>
                  <div class="text-primary">Chargement en cours ...</div>
                </div>
              </div>
              <div v-else>
                <form @submit.prevent="initSubmit">
                  <div class="input-group w-25 mt-4">
                    <input
                        type="number"
                        step="0.01"
                        class="form-control"
                        v-model="saleCertifiedModel.saleWithoutVat"
                        placeholder="Chiffre d'affaires HT"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text text-grey bg-white">HT</div>
                    </div>
                  </div>

                  <div class="text-danger" v-if="form.errors.errorSaleWithoutVat">
                    {{ form.errors.errorSaleWithoutVat }}
                  </div>

                  <div class="input-group w-25 mt-2">
                    <input
                        type="number"
                        step="0.01"
                        class="form-control w-50"
                        v-model="saleCertifiedModel.saleWithVat"
                        placeholder="Chiffre d'affaires T.T.C"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text text-grey bg-white">T.T.C</div>
                    </div>
                  </div>

                  <div class="text-danger" v-if="form.errors.errorSaleWithVat">
                    {{ form.errors.errorSaleWithVat }}
                  </div>

                  <div class="d-flex mt-3">
                    <div>
                      <input
                          type="submit"
                          class="btn btn-lg mt-2 btn-primary"
                          value="Valider"
                      />
                    </div>

                    <div v-if="saleCertifiedModel.delta" class="delta ml-4 mt-1">
                      <div class="card p-2">
                        <div v-if="form.send">
                          <div class="text-center mt-2" >
                            <b-spinner variant="success" label="Spinning"></b-spinner>
                            <div class="text-primary">Chargement en cours ...</div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="d-flex">
                            <b-icon class="my-auto mr-3" icon="info-circle" font-scale="3" variant="info"></b-icon>

                            <div>
                              <div class="total-sales">
                                Total CA du magasin sur l'année {{ this.datepicker.year }} :
                                <span class="text-primary">
                                  <animated-number
                                      :value="SaleCertifiedModule.totalSalesCertified"
                                      :formatValue="formatAnimatedNumber"
                                      :duration="600"
                                  />
                                </span>
                              </div>
                              <hr />
                              <div class="d-flex justify-content-between">
                                Delta :
                                <div class="text-right">
                                <span class="text-info big-text mt-1">
                                  <animated-number
                                      :value="saleCertifiedModel.delta"
                                      :formatValue="formatAnimatedNumber"
                                      :duration="600"
                                  />
                                </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      dataPage: {
        title: 'Gestion des CA certifiés'
      },
      loader: {
        isLoading: false
      },
      datepicker: {
        defaultDate: null,
        year: null,
        disabledDates: {
          from: new Date(new Date().getFullYear() -1, 0, 1),
        }
      },
      saleCertifiedModel: {
        shopId: null,
        year: null,
        saleWithVat: null,
        saleWithoutVat: null,
        delta: null
      },
      form: {
        errors: {
          errorSaleWithVat: null,
          errorSaleWithoutVat: null
        },
        send: false
      },
      actionForm: null
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- FORMAT ANIMATED NUMBER ------------------*/
    formatAnimatedNumber(value) {
      return `${value.toFixed(0)}` + ' €';
    },
    /*----------------- DATE ------------------*/
    handleDate($yearToSubtract, format)
    {
      let currentDate = moment().format(format)
      let newDate = moment(currentDate).subtract($yearToSubtract, 'year')

      return newDate.format(format)
    },
    /**
     * Format date for datepicker
     */
    formatDatepicker(date) {
      return moment(date).format('yyyy');
    },
    /**
     * Event click datePicker date
     *
     * @param value
     */
    clickDatePicker(value)
    {
      // Pass str to date Obj
      var dateObj = new Date(value)
      var momentObj = moment(dateObj)

      this.datepicker.year = Number(momentObj.format('YYYY'))

      // Get Sale certified
      this.$store.dispatch('fetchSaleCertified', {
        centerId: this.$store.getters.getChannel.center.id,
        shopId: this.$route.params.id,
        year: this.datepicker.year
      })
      .then(() => {
        // Sale certified Model
        this.saleCertifiedModel = this.setModelSaleCertifiedComputed

        // handle action form
        this.actionForm = (this.saleCertifiedModel.shopId) ? 'edit' : 'create'
      })
    },
    /*----------------- FORM ------------------*/
    initSubmit() {
      // Var
      this.form.send = true

      // Handle form errors
      if(this.saleCertifiedModel.saleWithoutVat === null) {
        this.form.errors = {
          errorSaleWithoutVat : 'Merci de renseigner un CA HT valide'
        }
      } else if(this.saleCertifiedModel.saleWithVat === null) {
        this.form.errors = {
          errorSaleWithVat : 'Merci de renseigner un CA T.T.C valide'
        }
      } else {
        // Handle total Sales
        this.$store.dispatch('fetchTotalSalesCertified', {
          centerId: this.$store.getters.getChannel.center.id,
          shopId: this.$route.params.id,
          year: this.datepicker.year
        })
          .then(() => {
            // Sale certified Model
            this.saleCertifiedModel.delta = this.saleCertifiedModel.saleWithoutVat - this.SaleCertifiedModule.totalSalesCertified

            this.form.send = false
            this.form.errors = {}
          })

        // create or update
        if(this.actionForm === 'edit') {
          this.editSaleCertified()
        } else {
          this.createSaleCertified()
        }
      }
    },
    /**
     * Create a new sale certified
     */
    createSaleCertified() {
      // Display loader
      this.showLoader()

      // Sale Certified model
      this.saleCertifiedModel.shopId = this.$route.params.id
      this.saleCertifiedModel.year = this.datepicker.year

      this.$store.dispatch('createSaleCertified',{
        centerId: this.$store.getters.getChannel.center.id,
        saleCertified: this.saleCertifiedModel
      })
        .then(() => {
          // Display loader
          this.hideLoader()

          this.actionForm = 'edit'
        })
        .catch((errors) => {
          this.errorsForm = errors
        })
    },
    /**
     * Update a new sale certified
     */
    editSaleCertified() {
      // Display loader
      this.showLoader()

      // Sale Certified model
      this.saleCertifiedModel.shopId = this.$route.params.id
      this.saleCertifiedModel.year = this.datepicker.year

      this.$store.dispatch('updateSaleCertified', {
        centerId: this.$store.getters.getChannel.center.id,
        saleCertified: this.saleCertifiedModel
      })
        .then(() => {
          // Display loader
          this.hideLoader()
        })
        .catch((errors) => {
          this.errorsForm = errors
        })
    }
  },
  created() {
    // loader
    this.showLoader()

    // Call data for fetch
    this.$store.dispatch('fetchShop', {
      centerId: this.$store.getters.getChannel.center.id,
      shopId: this.$route.params.id
    })

    // Set default value for datepicker
    this.datepicker.defaultDate = this.handleDate(1, 'YYYY')
    this.datepicker.year = Number(this.handleDate(1, 'YYYY'))

    // Fetch sale certified
    this.$store.dispatch('fetchSaleCertified', {
      centerId: this.$store.getters.getChannel.center.id,
      shopId: this.$route.params.id,
      year: this.datepicker.defaultDate
    })
      .then(() => {
        // Set sale certified model
        this.saleCertifiedModel = this.setModelSaleCertifiedComputed

        // handle action form
        this.actionForm = (this.saleCertifiedModel.shopId != null) ? 'edit' : 'create'

        // Hide loader
        this.hideLoader()
      })
  },
  updated() {
    // Handle total Sales
    this.$store.dispatch('fetchTotalSalesCertified', {
      centerId: this.$store.getters.getChannel.center.id,
      shopId: this.$route.params.id,
      year: this.datepicker.year
    })
      .then(() => {
        // Sale certified Model
        if(this.saleCertifiedModel.saleWithoutVat != null) {
          this.saleCertifiedModel.delta = Math.round((this.saleCertifiedModel.saleWithoutVat - this.SaleCertifiedModule.totalSalesCertified) * 100) / 100
        } else {
          this.saleCertifiedModel.delta = null
        }

        this.form.send = false

        this.hideLoader()
      })
  },
  computed: {
    setModelSaleCertifiedComputed()
    {
      return {
        shopId: (this.SaleCertifiedModule.saleCertified && this.SaleCertifiedModule.saleCertified.shop) ? this.SaleCertifiedModule.saleCertified.shop.id : null,
        year: (this.SaleCertifiedModule.saleCertified) ? this.SaleCertifiedModule.saleCertified.year : null,
        saleWithVat: (this.SaleCertifiedModule.saleCertified) ? this.SaleCertifiedModule.saleCertified.saleWithVat : null,
        saleWithoutVat: (this.SaleCertifiedModule.saleCertified) ? this.SaleCertifiedModule.saleCertified.saleWithoutVat : null,
        delta: null
      }
    },
    ...mapState(['ChannelModule']),
    ...mapState(['ShopModule']),
    ...mapState(['SaleCertifiedModule']),
  },
  components: {
    Datepicker: () => import('vuejs-datepicker'),
    AnimatedNumber: () => import('animated-number-vue'),
  }
}
</script>

<style scoped>

</style>
