<template>
  <div id="page-company-edit">
    <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
      <div class="text-center">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
    <div v-else>
      {{ /* Heading page */ }}
      <div class="header-pages-block d-md-flex justify-content-between mb-4">
        <h5>{{ dataPage.title }} <strong>{{ CompanyModule.company.name }}</strong></h5>
        <div>
          <router-link
              class="btn btn-outline-primary mt-3 mt-md-0"
              :to="{ name: 'company-list' }"
          >
            retour
          </router-link>
        </div>
      </div>

      {{ /* Form component */ }}
      <FormCrudCompany
          :company="CompanyModule.company"
          :id="CompanyModule.company.id"
          :actionForm="'edit'"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      dataPage: {
        title: 'Edition de l\'entreprise : '
      },
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  async created() {
    this.showLoader()

    await this.$store.dispatch('fetchCompany', this.$route.params.id)
      .then(() => {
        setTimeout(function () { this.hideLoader() }.bind(this), 250)
      })
  },
  computed: {
    ...mapState(['CompanyModule'])
  },
  components: {
    FormCrudCompany: () => import('@/components/pages/company/FormCrudCompany')
  }
}
</script>

<style scoped>

</style>
