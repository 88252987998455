import EventService from '@/services/EventService'

export default {
  state: {
    events: {},
  },
  mutations: {
    SET_EVENTS(state, events) {
      state.events = events
    }
  },
  actions: {
    /**
     * Fetch in state list of shops
     *
     * @param commit
     */
    fetchEvents({ commit }) {
      EventService.getEvents()
        .then(response => {
          commit('SET_EVENTS', response.data)
        })
        .catch(error => {
          console.log('There was an error on event list: ', error)
        })
    }
  }
}
