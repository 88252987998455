import UserService from '@/services/UserService'
import PublicService from '@/services/PublicService'

export default {
  state: {
    user: {},
    tempUser: {},
    users: [],
    propertiesInfos: [],
    total: null,
    totalRequest: null,
    roles: [
      {'index': ["ROLE_ADMIN"], 'name' : 'Admin'},
      {'index': ["ROLE_SHOP"], 'name' : 'Commerce'},
      {'index': ["ROLE_MARKETING"], 'name' : 'Marketing'}
    ],
  },
  mutations: {
    ADD_USER(state, user) {
      state.users.push(user)
    },
    SET_USERS(state, users) {
      state.users = users
    },
    SET_USERS_TOTAL(state, total) {
      state.total = total
    },
    SET_USERS_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_TEMP_USER(state, user) {
      state.tempUser = user
    }
  },
  actions: {
    /**
     * Fetch in state list of users
     *
     * @param commit
     * @param centerId
     * @param perPage
     * @param page
     * @param order
     * @param search
     */
    fetchUsers({ commit }, { centerId, perPage, page, order, search }) {
      return UserService.getUsers(
        centerId,
        (perPage) ? perPage: null,
        (page) ? page: null,
        (order) ? order : null,
        (search) ? search : null)
        .then(response => {
          commit('SET_USERS', response.data.list)
          commit('SET_USERS_TOTAL', response.data.total)
          commit('SET_USERS_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on user list: ', error)
        })
    },
    /**
     * Fetch in state a user
     *
     * @param commit
     * @param centerId
     * @param model
     */
    fetchUser({ commit }, model)
    {
      UserService.getUser(model.centerId, model.userId)
        .then(response => {
          commit('SET_USER', response.data)
        })
        .catch((error) => {
          console.log('There was an error on user: ', error)
        })
    },
    /**
     * Create user
     *
     * @param commit
     * @param model
     */
    createUser({ commit }, model)
    {
      return UserService.create(model.centerId, model.user)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_USER', model.user)
          return Promise.resolve()
        })
    },
    /**
     * Update user
     *
     * @param commit
     * @param model
     * @returns {*}
     */
    updateUser({ commit }, model)
    {
      return UserService.edit(model.centerId, model.user.id, model.user)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_USER', response.data)
        })
    },
    /**
     * Update user
     *
     * @param commit
     * @param model
     * @returns {*}
     */
    updateIsActiveUser({ commit }, model)
    {
      return UserService.editIsActive(model.centerId, model.user.id, model.user)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_USER', response.data)
        })
    },
    /**
     * Get public user
     *
     * @param commit
     * @param token
     * @param id
     * @returns {*}
     */
    getPublicUser({ commit }, {token, id})
    {
      return PublicService.getPublicUser(token, id)
        .then((response) => {

          if(response.data.error_wrong_user || response.data.error_token)
          {
            return Promise.reject(response.data)
          }

          commit('SET_TEMP_USER', response.data.user)
        })
    },
    /**
     * Update password
     *
     * @param commit
     * @param token
     * @param id
     * @param data
     * @returns {*}
     */
    updatePassword({ commit }, {token, id, data})
    {
      return PublicService.updatePassword(token, id, data)
        .then((response) => {
          if(response.data.error_token || response.data.error_wrong_user)
          {
            return Promise.resolve(response.data)
          }

          commit('SET_TEMP_USER', response.data)
        })
    }
  },
  getters: {
    /**
     * Get user by id in list users
     *
     * @param state
     * @returns {function(*): *}
     */
    getUserById: state => id => {
      return state.users.find(user => user.id === id)
    }
  }
}
