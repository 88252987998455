import AxiosService from '@/services/AxiosService'
import UrlBuilder from "@/helper/urlBuilder";

export default {
  /**
   * Get list of shop dashboard
   *
   * @params {}
   * @returns {*}
   */
  getShopsDashboard(params)
  {
    return AxiosService.callApi().get(UrlBuilder('dashboard', params.centerId, {
      date: params.date
    }))
  },
}
