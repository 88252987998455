import AxiosService from '@/services/AxiosService'

export default {
  /**
   * Get shop by id
   *
   * @param centerId
   * @param shopId
   * @returns {*}
   */
  getShop(centerId, shopId)
  {
    return AxiosService.callApi().get('/' + centerId + '/sales/shop/' + shopId)
  },
  /**
   * Get month less one list of sales
   *
   * @param routeParams
   * @returns {*}
   */
  getSales(routeParams) {
    return AxiosService.callApi().get('/' + routeParams.centerId + '/sales/list/' + routeParams.month + '/' + routeParams.year + '/' + routeParams.shopId)
  },
  /**
   * Create sale
   *
   * @param centerId
   * @param data
   * @returns {*}
   */
  create(centerId, data) {
    return AxiosService.callApi().post('/' + centerId + '/sales', data)
  },
  /**
   * Send email for ask help
   *
   * @param model
   * @returns {*}
   */
  sendEmailAskForHelp(model) {
    return AxiosService.callApi().post('/' + model.centerId + '/sales/askHelp', model)
  }
}
