<template>
  <div id="page-export-marketing">
    {{ /* Loader */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="file-earmark-arrow-down" font-scale="1.5" variant="primary"></b-icon> {{ dataPage.title }}
      </h3>
    </div>

    <hr />

    <div class="row">
      <div class="offset-xl-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 p-0 mb-2">
        <div class="card p-2">
          <div class="d-flex">
            <div class="my-auto mb-4 w-100 text-center">
              <h5>Choississez l'export que vous souhaitez :</h5>
              <br />

              <div class="d-flex justify-content-center">
                <input class="mt-1" type="radio" name="isMonth" value="false" id="is-month-daily" v-model="configExport.isMonth">
                <label for="is-month-daily" class="ml-2">Journalier</label>
                <input class="mt-1 ml-4" type="radio" name="isMonth" value="true" id="is-month-month" v-model="configExport.isMonth">
                <label for="is-month-month" class="ml-2">Mensuel</label>
              </div>

            </div>

            <div class="col-6">
              <h5 class="text-center">Merci de choisir une période</h5>

              <div class="form-group">
                <label for="date-start">Date de début</label>
                <Datepicker input-class="w-100" id="date-start" v-model="configExport.startDate" :format="configExport.formatDate"></Datepicker>
                <label class="mt-2" for="date-end">Date de fin</label>
                <Datepicker input-class="w-100" id="date-end" v-model="configExport.endDate" :format="configExport.formatDate"></Datepicker>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


    {{ /* Block config */ }}
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 p-0">
        <div class="card">
          <div class="card-header p-0 bg-white">
            <div class="d-flex justify-content-between h-100">
              <div class="col-10">
                <div class="ml-3 mt-3 mb-3">
                  <h5>Choisir une ou des catégories :</h5>
                </div>
              </div>
              <div class="col-2 border-left d-flex justify-content-center bg-grey-light">
                <div class="d-flex flex-column justify-content-center">
                  <button
                      v-on:click="toggleExpandAll()"
                      :class="(isAllOpen) ? 'btn btn-sm btn-success' : 'btn btn-sm btn-danger'">
                    <b-icon
                        :icon="(isAllOpen) ? 'folder2-open' : 'folder2'"
                        font-scale="1.5"
                        variant="white"
                        class="icon-folder"
                    ></b-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">

            <div class="checkbox-all-parent-cat mb-4 w-100">
              <input
                  class="form-check-input"
                  type="checkbox"
                  @change="selectAllCatParent($event)"
                  :checked="false"
              >
              <span class="ml-2">Tout sélectionner </span>
            </div>

            <div v-for="(parent, index) in CategoryModule.exportParentCategories" :key="index">
              <ul class="">
                <node-tree
                    :node="parent"
                    :is-export="true"
                    :is-all-open="isAllOpen"
                    :is-all-checked="isAllChecked"
                    :event-selectable-cat="eventSelectableCat"
                    @selectCat="eventSelectableCat"
                ></node-tree>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {{ /* Block shops */ }}
      <div v-if="listSelectableCat.length > 0" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 mt-md-2 mt-xl-0 p-0 mb-5">
        <div class="d-flex justify-content-between">
          <h5 class="ml-3 my-auto">Choisir les magasins :</h5>
          {{ /* Button check / un-checked shops */ }}
          <div class="text-right mr-4">
            <button class="btn mr-2 mb-3 mt-2" :class="dataPage.buttonAllSelectableShop.cssClass" v-on:click="handleSelectAllShop">
              {{ dataPage.buttonAllSelectableShop.title }}
            </button>
          </div>
        </div>

        <div class="">
          <Isotope
              :list="listSelectableCat"
              :options="isotope.options"
              @layout="isotope.currentLayout"
              class="iso-default"
          >
            <div
                v-for="(cat, indexCat) in listSelectableCat"
                :key="indexCat"
                class="ml-2 mt-2"
                :class="(cat.shops.length > 0) ? '' : 'd-none'">
              <div v-if="cat.shops.length > 0">
                <div class="border-0 card card-export-shop-isotope mb-2 p-2">
                  <span v-if="cat.shops[0] != null && cat.shops.length > 0"  :style="{color: cat.code}"><b>{{ (cat.name) ? cat.name : ''}}</b></span>
                  <hr v-if="cat.shops[0] != null && cat.shops.length > 0"  />

                  <div v-if="cat.shops && cat.shops.length > 0">
                    <div v-for="(shop, index) in cat.shops" :key="index">
                      <div class="col-12">
                        <div class="card card-export-shop mb-1">
                          <div class="d-flex">
                            <div class="div-checkbox-node-shop">
                              <input
                                  class=""
                                  type="checkbox"
                                  :id="shop.id"
                                  v-on:change="eventSelectableShop($event, cat)"
                                  :value="shop.id"
                                  :checked="true"
                              >
                            </div>

                            <div class="w-100 d-flex justify-content-center mt-2">
                              {{ shop.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Isotope>
        </div>
      </div>
    </div>

    {{ /* EXPORT */ }}
    <div class="container-button-csv w-100 app-box-shadow p-4" v-if="configExport.isOk">
      <button
          class="btn btn-success"
          v-on:click="validateConfExport"
      >
        Valider la configuration de l'export
      </button>
    </div>

  </div>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'
import axios from 'axios'
import $ from "jquery";

export default {
  data() {
    return {
      isotope: {
        currentLayout: 'masonry',
        selectLayout: null,
        layouts: [
          "masonry",
          "fitRows",
          "cellsByRow",
          "vertical",
          "packery",
          "masonryHorizontal",
          "fitColumns",
          "cellsByColumn",
          "horiz"
        ],
        options: {
          masonry: {
            columnWidth: 50
          }
        }
      },
      isAllOpen: null,
      isAllChecked: null,
      selectableCat: null,
      listSelectableShop: [],
      listSelectableCat: [],
      listExportMarketJson: [],
      dataPage: {
        title: 'Export(s) Marketing',
        buttonAllSelectableShop: {
          title: 'Décocher',
          cssClass: 'btn-outline-danger'
        }
      },
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
        color: '#28a745'
      },
      configExport: {
        startDate: null,
        endDate: null,
        formatDate: 'dd/MM/yyyy',
        isMonth: null,
        isOk: false,
        isSend: false
      },
      fetchCustomObj: [],
      configTableComponent: {
        headerList : [
          {'index' : 'name', 'name' : 'Nom', 'align' : 'left', 'filter': false },
          {'index' : 'lot', 'name' : 'Lot', 'align' : 'right', 'filter': false },
          {'index' : 'vatRate', 'name' : 'Numéro de TVA', 'align' : 'right', 'filter': false },
          {'index' : 'surface', 'name' : 'Surface', 'align' : 'right', 'filter': false },
          {'index' : 'openDate', 'name' : 'Date d\'ouverture', 'align' : 'center', 'filter': false, 'date' : true },
          {'index' : 'dateLimitSale', 'name' : 'Date limite de CA', 'align' : 'center', 'filter': false },
          {'index' : 'contactEmail', 'name' : 'Email du contact', 'align' : 'left', 'filter': false },
        ]
      }
    }
  },
  methods: {
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /**
     * Toggle expand all folder
     */
    toggleExpandAll() {
      this.isAllOpen = !this.isAllOpen
    },
    selectAllCatParent(event)
    {
      // Get all parent cat
      let parentsCat = this.CategoryModule.exportParentCategories

      if(event.target.checked)
      {
        // Set all checked to child component NodeTree
        this.isAllChecked = true

        parentsCat.forEach((cat) => {
          this.listSelectableCat.push(cat)

          // Handle push shop
          this.handleRecursiveCatChildrenPush(cat)
        })
      }
      else
      {
        // Set all checked to child component NodeTree
        this.isAllChecked = false

        this.listSelectableCat = []
        this.listSelectableShop = []
      }
    },
    handleSelectAllShop()
    {
      let that = this

      this.isAllSelectableShop = this.isAllSelectableShop === false

      if(!this.isAllSelectableShop)
      {
        $('.div-checkbox-node-shop input').each(function() {
          $(this).prop('checked', false)
        })

        this.listSelectableShop = []

        // Handle button
        this.dataPage.buttonAllSelectableShop.title = 'Tout cocher'
        this.dataPage.buttonAllSelectableShop.cssClass = 'btn-outline-success'
      }
      else
      {
        $('.div-checkbox-node-shop input').each(function() {
          let id = $(this).attr('id')

          $(this).prop('checked', true)

          that.listSelectableShop.push(Number(id))
        })

        // Handle button
        this.dataPage.buttonAllSelectableShop.title = 'Décocher'
        this.dataPage.buttonAllSelectableShop.cssClass = 'btn-outline-danger'
      }
    },
    /**
     * Remove or add shop
     * Checkbox
     */
    eventSelectableShop(dataEvent, cat) {
      this.getSelectableShop(dataEvent, cat)
    },
    /**
     * Event checked for node tree component
     */
    eventSelectableCat(dataEvent) {
      this.getSelectableCat(dataEvent)
    },
    /**
     * Handle selectable cat list
     *
     * @param dataEvent
     */
    getSelectableCat(dataEvent)
    {
      if(dataEvent.parent === null) {
        this.shops = (this.$store.getters.getExportParentCategoryById(dataEvent.id).shops) ? this.$store.getters.getExportParentCategoryById(dataEvent.id).shops : []
        this.selectableCat = this.$store.getters.getExportParentCategoryById(dataEvent.id)
      } else {
        this.shops = this.$store.getters.getExportCategoryById(dataEvent.parent, dataEvent.id).shops
        this.selectableCat = this.$store.getters.getExportCategoryById(dataEvent.parent, dataEvent.id)
      }

      // If shop input was checked
      if(dataEvent.checked)
      {
        this.listSelectableCat.push(this.selectableCat)

        // Handle push shop
        if(this.selectableCat.shops && this.selectableCat.shops.length > 0)
        {
          this.selectableCat.shops.forEach(shop => {
            this.listSelectableShop.push(shop.id)
          })
        }

        this.handleRecursiveCatChildrenPush(this.selectableCat)
      }
      else
      {
        // Handle cat
        let indexCat = this.listSelectableCat.findIndex(obj => obj.id === dataEvent.id)
        if (indexCat > -1) {
          this.listSelectableCat.splice(indexCat, 1)

          // Handle children
          if(this.selectableCat.childs.length > 0)
          {
            this.handleRecursiveCatChildForSplice(this.selectableCat)
          }

          // Handle splice shop
          if(this.selectableCat.shops && this.selectableCat.shops.length > 0)
          {
            this.selectableCat.shops.forEach(shop => {
              let indexShop = this.listSelectableShop.findIndex(id => id === shop.id)
              if (indexShop > -1) {
                this.listSelectableShop.splice(indexShop, 1)
              }
            })
          }
        }
      }
    },
    /**
     * Recursive for get child on cat and push
     */
    handleRecursiveCatChildrenPush(cat)
    {
      if(cat.childs.length > 0)
      {
        cat.childs.forEach(child => {
            // List cat
            this.listSelectableCat.push(child)

            // Handle push shop
            if(child.shops && child.shops.length > 0)
            {
              child.shops.forEach(shop => {
                this.listSelectableShop.push(shop.id)
              })
            }

            this.handleRecursiveCatChildrenPush(child)
        })
      }
    },
    /**
     * Recursive for get child on cat and splice
     */
    handleRecursiveCatChildForSplice(cat)
    {
      cat.childs.forEach(child => {
        // Remove cat
        let indexChild = this.listSelectableCat.findIndex(obj => obj.id === child.id)
        if(indexChild > -1) {
          this.listSelectableCat.splice(indexChild, 1)
          this.handleRecursiveCatChildForSplice(child)
        }

        // Handle splice shop
        if(child.shops && child.shops.length > 0)
        {
          child.shops.forEach(shop => {
            let indexShop = this.listSelectableShop.findIndex(id => id === shop.id)
            if (indexShop > -1) {
              this.listSelectableShop.splice(indexShop, 1)
            }
          })
        }
      })
    },
    /**
     * Handle selectable shop list
     *
     * @param dataEvent
     * @param cat
     */
    getSelectableShop(dataEvent, cat) {
      // If shop input checked was checked
      if(dataEvent.target.checked)
      {
        this.listSelectableShop.push(parseInt(dataEvent.target.id))
      }
      else
      {
        // Handle shop
        let indexShop = this.listSelectableShop.findIndex(id => id === parseInt(dataEvent.target.id))
        if (indexShop > -1) {
          this.listSelectableShop.splice(indexShop, 1)
        }

        // Handle cat
        let indexCat = this.listSelectableCat.findIndex(obj => obj === cat)
        if (indexCat > -1 && dataEvent.target.countElemChecked === 0) {
          this.listSelectableCat.splice(indexCat, 1)
        }
      }

      // Get count of this listSelectableShop
      this.isShopSelectable = this.listSelectableShop.length > 0
    },
    /**
     * Autocomplete date remove with classic date
     */
    handleDate()
    {
      if(this.configExport.startDate != null)
        this.configExport.startDate = moment(this.configExport.startDate).format('yyyy-MM-DD')
      if(this.configExport.endDate != null)
        this.configExport.endDate = moment(this.configExport.endDate).format('yyyy-MM-DD')
    },
    /**
     * Click on button validate conf export
     */
    async validateConfExport() {
      this.configExport.isSend = true

      // Change format date
      this.handleDate()

      let model = {
        conf: this.configExport,
        shopsId: this.listSelectableShop
      }

      // Display loader
      this.showLoader()

      let filename = ""

      // Fetch export marketing
      await this.$store.dispatch('csvExportMarketing', model)
        .then((response) => {
          filename = response.filename

          // Axios call for download CSV form API
          axios({
            url: response.path,
            method: 'GET',
            responseType: 'blob',
          })
            .then((response) => {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]))
              var fileLink = document.createElement('a')

              fileLink.href = fileURL
              fileLink.setAttribute(
                  'download',
                  filename
              )
              document.body.appendChild(fileLink)

              fileLink.click();
            })

          // Reset confExport / ListSelectableShop / listSelectableCat / IsAllOpen
          this.configExport.startDate = null
          this.configExport.endDate = null
          this.configExport.isMonth = null
          this.configExport.isOk = false
          this.configExport.isSend = false

          this.listSelectableShop = []
          this.listSelectableCat = []

          this.isAllOpen = false

          this.hideLoader()
        })
      .catch((error) => {
        this.hideLoader()

        console.log('ERROR, when you try to download CSV Marketing, plz contact a webmaster', error)
      })
    },
  },
  watch: {
    /**
     * Check if startDate / endDate / isMonth and selectableShop was correctly fill
     * @param confValue
     */
    configProperty(confValue) {
      this.configExport.isOk = confValue[0] != null && confValue[1] != null && confValue[2] != null && confValue[3] !== 0;
    },
  },
  created() {
    this.showLoader()

    // Fetch PARENT category
    this.$store.dispatch('fetchExportParentCategories', {
      perPage: null,
      page: this.page
    })
    .then(() => {
      this.hideLoader()
    })
    .catch(() => {
      this.hideLoader()
    })
  },
  computed: {
    configProperty() {
      return [this.configExport.startDate, this.configExport.endDate, this.configExport.isMonth, this.listSelectableShop.length]
    },
    ...mapState(['CenterModule']),
    ...mapState(['CategoryModule']),
    ...mapState(['ShopModule']),
    ...mapState(['ExportModule']),
    ...mapState(['UserModule'])
  },
  components: {
    Isotope: () => import('vueisotope'),
    Datepicker: () => import('vuejs-datepicker'),
    NodeTree: () => import('@/components/_shared/NodeTree'),
    Loading: () => import('vue-loading-overlay')
  }
}
</script>
