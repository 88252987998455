<template>
  <div class="page-shop-show mb-5">
    <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
      <div class="text-center">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
    <div v-else>
      {{ /* Heading page */ }}
      <div class="header-pages-block d-md-flex justify-content-between mb-5">
        <div>
          <h5>{{ dataPage.title }} <strong>{{ ShopModule.shop.name }}</strong></h5>
          <h5>Centre : <strong>{{ ShopModule.shop.center.name }}</strong></h5>
          <h6>Catégorie : <strong>{{ ShopModule.shop.category.name }}</strong></h6>
        </div>

        <div v-if="!this.$route.query.isDashboard">
          <div class="d-flex">
            <router-link
                class="btn btn-outline-primary mt-2 mt-md-0"
                :to="{ name: 'shop-list' }"
            >
              retour
            </router-link>
            <router-link
                class="btn btn-primary mt-2 ml-2 mt-md-0"
                :to="{ name: 'shop-edit' }"
            >
              Editer le magasin
            </router-link>
          </div>

        </div>
        <div v-else>
          <div class="d-flex">
            <router-link
                class="btn btn-outline-primary"
                :to="{ name: 'dashboard' }"
            >
              retour
            </router-link>
            <router-link
                class="btn btn-primary mt-2 ml-2 mt-md-0"
                :to="{ name: 'shop-edit' }"
            >
              Edit
            </router-link>
          </div>
        </div>
      </div>


      <h3>Nom, TVA, lot & surface</h3>
      <hr />

      <div class="d-flex mb-3">
        <span><strong>Nom</strong> : </span>
        <span class="ml-2">{{ ShopModule.shop.name }}</span>
      </div>

      <div class="d-flex mb-3">
        <span><strong>TVA</strong> : </span>
        <span class="ml-2">{{ ShopModule.shop.vatRate }}</span>
      </div>

      <div class="d-flex mb-3">
        <span><strong>Numéro de lot </strong> : </span>
        <span class="ml-2">{{ ShopModule.shop.lot }}</span>
      </div>

      <div class="d-flex mb-3">
        <span><strong>Surface</strong> : </span>
        <span class="ml-2">{{ ShopModule.shop.surface }} m<sup>2</sup></span>
      </div>

      <h3 class="mt-4 mb-4">Contact</h3>
      <hr />

      <div class="d-flex mb-3">
        <span><strong>Prénom du contact</strong> : </span>
        <span class="ml-2">{{ ShopModule.shop.contactFirstname }}</span>
      </div>

      <div class="d-flex mb-3">
        <span><strong>Nom du contact</strong> : </span>
        <span class="ml-2">{{ ShopModule.shop.contactLastname }}</span>
      </div>

      <div class="d-flex mb-3">
        <span><strong>Email du contact </strong> : </span>
        <span class="ml-2">{{ ShopModule.shop.contactEmail }}</span>
      </div>

      <div class="d-flex mb-3">
        <span><strong>Téléphone du contact</strong> : </span>
        <span class="ml-2">{{ ShopModule.shop.contactPhone }}</span>
      </div>

      <h3 class="mt-4 mb-4">Date(s)</h3>
      <hr />

      <div class="d-flex mb-3">
        <span><strong>Date d'ouverture</strong> : </span>
        <span class="ml-2">{{ ShopModule.shop.openDate | moment('DD/MM/Y') }}</span>
      </div>

      <div class="d-flex mb-3">
        <span><strong>Date de fermeture</strong> : </span>
        <span class="ml-2">{{ ShopModule.shop.closeDate | moment('DD/MM/Y') }}</span>
      </div>

      <div class="d-flex mb-3">
        <span><strong>Date de livraison de coque </strong> : </span>
        <span class="ml-2">{{ ShopModule.shop.hullDate | moment('DD/MM/Y') }}</span>
      </div>

      <h3 class="mt-4 mb-4">Config(s)</h3>
      <hr />

      <div class="d-flex">
        <span><strong>Double saisie du CA</strong> : </span>
        <span class="ml-2">{{ doubleSaleEntry }}</span>
      </div>

      <div class="d-flex">
        <span><strong>Saisie par mois du CA</strong> : </span>
        <span class="ml-2">{{ isMonth }}</span>
      </div>

      <div class="d-flex">
        <span><strong>Date limite du CA</strong> : </span>
        <span class="ml-2">{{ ShopModule.shop.dateLimitSale }}</span>
      </div>

      <div class="d-flex">
        <span><strong>Déclaration de CA</strong> : </span>
        <span class="ml-2">{{ (ShopModule.shop.isHaveSale) ? 'Oui' : 'Non' }}</span>
      </div>
    </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      doubleSaleEntry: '',
      isMonth: '',
      dataPage: {
        title: 'Détails du commerce : '
      },
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  async created() {
    this.showLoader()

    let model = {
      centerId: this.$store.getters.getChannel.center.id,
      shopId: this.$route.params.id
    }

    await this.$store.dispatch('fetchShop', model)
      .then(() => {
        setTimeout(function () { this.hideLoader() }.bind(this), 250)
      })
  },
  computed: {
    ...mapState(['ChannelModule']),
    ...mapState(['ShopModule'])
  },
  mounted() {
    this.doubleSaleEntry = (this.ShopModule.shop.doubleSaleEntry) ? "Oui" : "Non"
    this.isMonth = (this.ShopModule.shop.isMonth) ? "Oui" : "Non"
  },
  components: {
  }
}
</script>
