<template>
  <div id="page-export">
    {{ /* Loader */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="file-earmark-arrow-down" font-scale="1.5" variant="primary"></b-icon> {{ dataPage.title }}
      </h3>

      <div>
        <button class="btn btn-outline-danger mt-2" v-on:click="resetConf">
          Réinitialiser la config
        </button>
      </div>
    </div>

    <hr />

    {{/* BLOCK NAV TABS */}}
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-item nav-link c-pointer" :class="(tabs.tabIndex === 0) ? 'active' : ''" v-on:click="handleTabIndex(0)">
          <div class="mt-1 p-1">Configuration Export</div>
        </a>
        <a class="nav-item nav-link c-pointer" :class="(tabs.tabIndex === 1) ? 'active' : ''" v-show="configExport.showTabs" v-on:click="handleTabIndex(1)">
          <div v-if="html.cat !== ''" class="d-flex justify-content-between">
            <div class="my-auto text-tabs-category">Rendu Visuel de l'export (catégorie)</div>
            <div class="">
              <button
                  class="btn btn-sm btn-warning bg-tabs-category ml-3"
                  v-on:click="resetConfExportCats()"
              >
                <b-icon icon="arrow-repeat" font-scale="1.5" variant="white"></b-icon>
              </button>
            </div>
          </div>
          <div class="p-1 mt-1" v-else>
            <b-spinner variant="success" type="border" small></b-spinner>
          </div>
        </a>
        <a class="nav-item nav-link c-pointer" :class="(tabs.tabIndex === 2) ? 'active' : ''" v-show="configExport.showTabs" v-on:click="handleTabIndex(2)">
          <div v-if="html.shop !== ''" class="d-flex justify-content-between">
            <div class="my-auto text-tabs-shop">Rendu Visuel de l'export (magasins)</div>
            <div class="">
              <button
                  class="btn btn-sm btn-warning bg-tabs-shop ml-3"
                  v-on:click="resetConfExportShops()"
              >
                <b-icon icon="arrow-repeat" font-scale="1.5" variant="white"></b-icon>
              </button>
            </div>
          </div>
          <div class="p-1 mt-1" v-else>
            <b-spinner variant="success" type="border" small></b-spinner>
          </div>
        </a>
        <a class="nav-item nav-link c-pointer" :class="(tabs.tabIndex === 3) ? 'active' : ''" v-show="configExport.showTabs" v-on:click="handleTabIndex(3)">
          <div v-if="html.stats !== ''" class="d-flex justify-content-between">
            <div class="my-auto text-tabs-stats">Rendu Visuel de l'export (statistiques)</div>
            <div class="">
              <button
                  class="btn btn-sm btn-warning bg-tabs-stats ml-3"
                  v-on:click="resetConfExportStats()"
              >
                <b-icon icon="arrow-repeat" font-scale="1.5" variant="white"></b-icon>
              </button>
            </div>
          </div>
          <div class="p-1 mt-1" v-else>
            <b-spinner variant="success" type="border" small></b-spinner>
          </div>
        </a>
      </div>
    </nav>
    {{/* TAB CONTENT */}}
    <div class="tab-content" id="nav-tabContent">
      <b-card class="border-top-0" no-body>
        {{/* BLOCK CONFIG */}}
        <div class="fade" :class="(tabs.tabIndex === 0) ? 'show active' : ''" v-show="tabs.tabIndex === 0">
          <div class="config-tab">
            <b-alert v-if="configExport.showTabs" show variant="info" class="ml-4 mr-4 mt-4 mb-4">
              <h5 class="alert-heading">Changement de configuration</h5>
              <hr />
              <p>
                <b>Attention :</b> si vous changez la configuration,
                <br />
                Vous avez la possibilité de recharger chaque rendu visuel en cliquant sur leur boutton respectif,
                <br />
                présent dans les onglets au dessus.
                <button class="btn btn-sm btn-secondary ml-2">
                  <b-icon icon="arrow-repeat" font-scale="1.5" variant="white"></b-icon>
                </button>
              </p>

              <hr />
              <b>Sinon :</b> cliquez sur ce boutton pour recharger les rendus visuels
              <button class="btn btn-sm btn-primary ml-3" v-on:click="changeConfig">
                <b-icon icon="arrow-repeat" font-scale="1.5" variant="white"></b-icon> Recharger les rendu visuels ?
              </button>
            </b-alert>

            <div class="mt-3 row">
              <div class="col-12 col-md-4 col-lg-3 p-0 pl-3 mb-2 mx-auto">
                <div class="p-2">
                  <div class="">
                    <h5 class="text-center">Choisir la période :</h5>

                    <div class="form-group">
                      <Datepicker
                          input-class="w-100"
                          id="date-start"
                          v-model="configExport.date"
                          :format="configExport.formatDate"
                          :minimumView="'month'"
                          :maximumView="'month'"
                          @input="configExport.date = fixDate($event)"
                      >
                      </Datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {{ /* Block config */ }}
            <div class="ml-2 row" v-if="configExport.date">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 p-0 pl-3">
                <div class="card card-border">
                  <div class="card-header p-0 bg-white">
                    <div class="d-flex justify-content-between border-bottom h-100">
                      <div class="col-10">
                        <div class="ml-3 mt-3 mb-3">
                          <h5>Choisir une ou des catégories :</h5>
                        </div>
                      </div>
                      <div class="col-2 border-left d-flex justify-content-center bg-grey-light">
                        <div class="d-flex flex-column justify-content-center">
                          <button
                              v-on:click="toggleExpandAll()"
                              :class="(isAllOpen) ? 'btn btn-sm btn-success' : 'btn btn-sm btn-danger'">
                            <b-icon
                                :icon="(isAllOpen) ? 'folder2-open' : 'folder2'"
                                font-scale="1.5"
                                variant="white"
                                class="icon-folder"
                            ></b-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="checkbox-all-parent-cat mb-4 w-100">
                      <input
                          class="form-check-input"
                          type="checkbox"
                          @change="selectAllCatParent($event)"
                          :checked="false"
                      >
                      <span class="ml-2">Tout sélectionner </span>
                    </div>
                    <div v-for="(parent, index) in CategoryModule.exportParentCategories" :key="index">
                      <ul class="">
                        <node-tree
                            :node="parent"
                            :is-export="true"
                            :is-all-open="isAllOpen"
                            :is-all-checked="isAllChecked"
                            :event-selectable-cat="eventSelectableCat"
                            @selectCat="eventSelectableCat"
                        ></node-tree>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {{ /* Block shops */ }}
              <div v-if="listSelectableCat.length > 0 && configExport.showShop" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 mt-md-2 mt-xl-0 p-0 mb-5">
                <div class="d-flex justify-content-between">
                  <h5 class="ml-3 my-auto">Choisir les magasins :</h5>
                  {{ /* Button check / un-checked shops */ }}
                  <div class="text-right mr-4">
                    <button class="btn mr-2 mb-3 mt-2" :class="dataPage.buttonAllSelectableShop.cssClass" v-on:click="handleSelectAllShop">
                      {{ dataPage.buttonAllSelectableShop.title }}
                    </button>
                  </div>
                </div>

                <div class="">
                  <Isotope
                      :list="listSelectableCat"
                      :options="isotope.options"
                      @layout="isotope.currentLayout"
                      class="iso-default"
                  >
                    <div v-for="(cat, indexCat) in listSelectableCat"
                         :key="indexCat"
                         class="ml-2 mt-2"
                         :class="(cat.shops.length > 0) ? '' : 'd-none'"
                    >
                      <div v-if="cat.shops.length > 0">
                        <div class="card border-0 card-export-shop-isotope mb-2 p-2">
                          <span class="" v-if="cat.shops[0] != null && cat.shops.length > 0"  :style="{color: cat.code}"><b>{{ (cat.name) ? cat.name : ''}}</b></span>
                          <hr class="mt-1" v-if="cat.shops[0] != null && cat.shops.length > 0"  />

                          <div v-if="cat.shops && cat.shops.length > 0">
                            <div v-for="(shop, index) in cat.shops" :key="index">
                              <div class="col-12">
                                <div class="card card-border card-export-shop mb-1">
                                  <div class="d-flex">
                                    <div class="div-checkbox-node-shop">
                                      <input
                                          v-if="isCheckboxShopDisplay(shop.openDate, (shop.closeDate) ? shop.closeDate : null)"
                                          class=""
                                          type="checkbox"
                                          :id="shop.id"
                                          v-on:change="eventSelectableShop($event, cat)"
                                          :value="shop.id"
                                          :checked="true"
                                      >
                                    </div>

                                    <div class="w-100 d-flex justify-content-center mt-2" v-if="(!shop.closeDate)">
                                      <div v-if="configExport.date >= shop.openDate" >
                                        {{ shop.name }}
                                      </div>
                                      <div v-else class="w-100 d-flex justify-content-between pl-2 pr-2 mt-2">
                                        <div class="truncate text-warning w-50">{{ shop.name }}</div>
                                        <div class="small-text text-warning">Date d'ouverture : {{ shop.openDate|moment('DD/MM/YYYY') }}</div>
                                      </div>
                                    </div>
                                    <div v-else class="w-100 d-flex justify-content-between pl-2 pr-2 mt-2">
                                      <div class="truncate w-50" :class="(shop.closeDate <= configExport.date) ? 'text-danger' : ''">
                                        {{ shop.name }}
                                      </div>
                                      <div class="small-text" :class="(shop.closeDate <= configExport.date) ? 'text-danger' : ''">
                                        Date de fermeture : {{ shop.closeDate|moment('DD/MM/YYYY') }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Isotope>
                </div>
              </div>
            </div>
          </div>
        </div>
        {{/* BLOCK CAT */}}
        <div class="tab-pane mb-5 fade" :class="(tabs.tabIndex === 1) ? 'show active' : ''" v-show="tabs.tabIndex === 1">
          <div class="text-center mt-2" v-if="html.cat === '' || tabs.isLoadingCat">
            <div class="mt-5">
              <b-spinner variant="success" label="Spinning"></b-spinner>
              <div class="text-primary">Chargement en cours ...</div>
            </div>
          </div>

          <div class="response-api-html-export-cat" v-show="!tabs.isLoadingCat">
            <div v-html="html.cat">
            </div>
          </div>

          <div class="response-api-html-export-cat-temp d-none">
            <div v-html="html.catTemp">
            </div>
          </div>
        </div>
        {{/* BLOCK SHOP */}}
        <div class="tab-pane mb-5 fade" :class="(tabs.tabIndex === 2) ? 'show active' : ''" v-show="tabs.tabIndex === 2">
          <div class="text-center mt-2" v-if="html.shop === '' || tabs.isLoadingShop">
            <div class="mt-5">
              <b-spinner variant="success" label="Spinning"></b-spinner>
              <div class="text-primary">Chargement en cours ...</div>
            </div>
          </div>

          <div class="response-api-html-export-shop" v-show="!tabs.isLoadingShop">
            <div v-html="html.shop">
            </div>
          </div>

          <div class="response-api-html-export-shop-temp d-none">
            <div v-html="html.shopTemp">
            </div>
          </div>
        </div>
        {{/* BLOCK STATS */}}
        <div class="tab-pane mb-5 fade" :class="(tabs.tabIndex === 3) ? 'show active' : ''" v-show="tabs.tabIndex === 3">
          <div class="text-center mt-2" v-if="html.stats === '' || tabs.isLoadingStats">
            <div class="mt-5">
              <b-spinner variant="success" label="Spinning"></b-spinner>
              <div class="text-primary">Chargement en cours ...</div>
            </div>
          </div>
          <div v-else>
            <div class="response-api-html-export-stats" v-show="!tabs.isLoadingStats"  v-on:keyup="ChangeTempInput">
              <div v-html="html.stats">
              </div>
            </div>

            {{ /* APEX CHART */ }}
            <div class="mb-5" v-show="!tabs.isLoadingStats">
              <ApexChart id="chart-stats" ref="chartStats" height="400" :options="apexChart.options" :series="apexChart.series" :key="1"></ApexChart>
            </div>

            <div class="response-api-html-export-stats-temp d-none">
              <div v-html="html.statsTemp">
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>

    {{ /* EXPORT */ }}
    <div class="container-button-csv app-box-shadow p-4" v-if="configExport.isOk">
      <div class="d-flex justify-content-start">
        <div v-if="html.isHtmlCatGenerate" class="w-100">
          <div class="d-flex justify-content-start">
            <div>
              <button class="btn btn-lg btn-primary bg-tabs-category" v-on:click="sendHtmlExportCats">Générer le PDF (catégorie)</button>
              <button class="btn btn-lg btn-primary bg-tabs-shop ml-2" v-on:click="sendHtmlExportShops">Générer le PDF (magasin)</button>
              <button class="btn btn-lg btn-primary bg-tabs-stats ml-2" v-on:click="sendHtmlExportStats">Générer le PDF (statistique)</button>
            </div>
          </div>
        </div>
        <div v-else class="w-100 d-flex justify-content-start">
          <button
              class="btn btn-lg btn-success ml-2"
              v-on:click="eventClickButtonMadeVisual()"
          >
            Voir le rendu visuel
          </button>

          <div>
            <button
                class="btn btn-lg btn-info ml-4"
                v-on:click="eventClickExportCsv()"
            >
              Export CSV
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import {mapState} from 'vuex'
import moment from 'moment'
import exportService from '../../../services/ExportService'
import axios from 'axios'

export default {
  data() {
    return {
      isotope: {
        currentLayout: 'masonry',
        selectLayout: null,
        layouts: [
          "masonry"
        ],
        containerStyle: {
          overflow: 'auto'
        },
        options: {
          masonry: {
            columnWidth: 30
          }
        }
      },
      html: {
        cat: '',
        catTemp: '',
        shop: '',
        shopTemp: '',
        stats: '',
        statsTemp: '',
        isHtmlCatGenerate: false,
        isHtmlShopGenerate: false,
        isHtmlStatGenerate: false,
      },
      tabs: {
        tabIndex: 0,
        isLoadingCat: false,
        isLoadingShop: false,
        isLoadingStats: false
      },
      apexChart: {
        base64: null,
        id: 'chart-stats',
        isGenerate: false,
        options: {
          chart: {
            toolbar: {
              show: false
            },
          },
          legend: {
            show: false
          },
          xaxis: {
            type: 'category'
          },
          colors: ['#007bff', '#28a745', '#6c757d'],
        },
        series: this.setChartSeriesComputed
      },
      shops: [],
      isAllOpen: null,
      isAllChecked: null,
      isAllSelectableShop: null,
      selectableCat: null,
      listSelectableShop: [],
      listSelectableCat: [],
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
        color: '#28a745'
      },
      configExport: {
        date: null,
        formatDate: 'MM/yyyy',
        isOk: false,
        showShop: false,
        showTabs: false
      },
      dataPage: {
        title: 'Export(s) Admin',
        buttonAllSelectableShop: {
          title: 'Décocher',
          cssClass: 'btn-outline-danger mb-2'
        }
      },
      configTableComponent: {
        headerList : [
          {'index' : 'name', 'name' : 'Nom', 'align' : 'left', 'filter': false },
          {'index' : 'lot', 'name' : 'Lot', 'align' : 'right', 'filter': false },
          {'index' : 'vatRate', 'name' : 'Numéro de TVA', 'align' : 'right', 'filter': false },
          {'index' : 'surface', 'name' : 'Surface', 'align' : 'right', 'filter': false },
          {'index' : 'contactEmail', 'name' : 'Email du contact', 'align' : 'left', 'filter': false },
        ]
      }
    }
  },
  created() {
    this.showLoader()

    // Fetch PARENT category
    this.$store.dispatch('fetchExportParentCategories', {
      perPage: null,
      page: this.page
    })
    .then(() => {
      this.hideLoader()
    })
  },
  updated() {
  },
  methods: {
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    fixDate(event) {
      return moment(event).format('YYYY-MM-') + moment(event).daysInMonth()
    },
    handleDateConf()
    {
      if(this.configExport.startDate != null)
        this.configExport.startDate = moment(this.configExport.startDate).format('yyyy-MM-DD')
    },
    /**
     * Toggle expand all folder
     */
    toggleExpandAll()
    {
      this.isAllOpen = !this.isAllOpen
    },
    selectAllCatParent(event)
    {
      // Get all parent cat
      let parentsCat = this.CategoryModule.exportParentCategories

      if(event.target.checked)
      {
        // Set all checked to child component NodeTree
        this.isAllChecked = true

        parentsCat.forEach((cat) => {
          this.listSelectableCat.push(cat)

          // Handle push shop
          this.handleRecursiveCatChildrenPush(cat)
        })
      }
      else
      {
        // Set all checked to child component NodeTree
        this.isAllChecked = false

        this.listSelectableCat = []
        this.listSelectableShop = []
      }

    },
    // eslint-disable-next-line no-unused-vars
    isCheckboxShopDisplay(openDate, closeDate = null)
    {
        // @deprecated
        /*
        if(closeDate > this.configExport.date) {
          return true
        } else if(closeDate && closeDate < this.configExport.date) {
          return false
        } else if(openDate < this.configExport.date) {
          return true
        } else {
          return false
        }
        */

      return true
    },
    handleSelectAllShop()
    {
      let that = this

      this.isAllSelectableShop = this.isAllSelectableShop === false

      if(!this.isAllSelectableShop) {
        $('.div-checkbox-node-shop input').each(function() {
          $(this).prop('checked', false)
        })

        this.listSelectableShop = []

        // Handle button
        this.dataPage.buttonAllSelectableShop.title = 'Tout cocher'
        this.dataPage.buttonAllSelectableShop.cssClass = 'btn-outline-success'
      } else {
        that.listSelectableShop = []

        $('.div-checkbox-node-shop input').each(function() {
          let id = $(this).attr('id')

          $(this).prop('checked', true)

          that.listSelectableShop.push(Number(id))
        })

        // Handle button
        this.dataPage.buttonAllSelectableShop.title = 'Décocher'
        this.dataPage.buttonAllSelectableShop.cssClass = 'btn-outline-danger'
      }
    },
    /**
     * Event checked for node tree component
     */
    eventSelectableCat(dataEvent) {
      this.getSelectableCat(dataEvent)
    },
    /**
     * Remove or add shop
     * Checkbox
     */
    eventSelectableShop(dataEvent, cat) {
      this.getSelectableShop(dataEvent, cat)
    },
    /**
     * Handle selectable cat list
     *
     * @param dataEvent
     */
    getSelectableCat(dataEvent)
    {
      if(dataEvent.parent === null) {
        this.shops = (this.$store.getters.getExportParentCategoryById(dataEvent.id).shops) ? this.$store.getters.getExportParentCategoryById(dataEvent.id).shops : []
        this.selectableCat = this.$store.getters.getExportParentCategoryById(dataEvent.id)
      } else {
        this.shops = this.$store.getters.getExportCategoryById(dataEvent.parent, dataEvent.id).shops
        this.selectableCat = this.$store.getters.getExportCategoryById(dataEvent.parent, dataEvent.id)
      }

      // If cat input was checked
      if(dataEvent.checked) {
        this.listSelectableCat.push(this.selectableCat)

        // Handle push shop
        if(this.selectableCat.shops && this.selectableCat.shops.length > 0) {
          this.selectableCat.shops.forEach(shop => {
            if(this.isCheckboxShopDisplay(shop.openDate, shop.closeDate)) {
              this.listSelectableShop.push(shop.id)
            }
          })
        }

        this.handleRecursiveCatChildrenPush(this.selectableCat)
      } else {
        // Handle cat
        let indexCat = this.listSelectableCat.findIndex(obj => obj.id === dataEvent.id)
        if (indexCat > -1) {
          this.listSelectableCat.splice(indexCat, 1)

          // Handle children
          if(this.selectableCat.childs.length > 0) {
            this.handleRecursiveCatChildForSplice(this.selectableCat)
          }

          // Handle splice shop
          if(this.selectableCat.shops && this.selectableCat.shops.length > 0) {
            this.selectableCat.shops.forEach(shop => {
              let indexShop = this.listSelectableShop.findIndex(id => id === shop.id)
              if (indexShop > -1) {
                this.listSelectableShop.splice(indexShop, 1)
              }
            })
          }
        }
      }
    },
    /**
     * Recursive for get child on cat and push
     */
    handleRecursiveCatChildrenPush(cat)
    {
      if(cat.childs.length > 0) {
        cat.childs.forEach(child => {
          // List cat
          this.listSelectableCat.push(child)

          // Handle push shop
          if(child.shops && child.shops.length > 0) {
            child.shops.forEach(shop => {
              if(this.isCheckboxShopDisplay(shop.openDate, shop.closeDate)) {
                this.listSelectableShop.push(shop.id)
              }
            })
          }

          this.handleRecursiveCatChildrenPush(child)
        })
      }
    },
    /**
     * Recursive for get child on cat and splice
     */
    handleRecursiveCatChildForSplice(cat)
    {
      cat.childs.forEach(child => {
        // Remove cat
        let indexChild = this.listSelectableCat.findIndex(obj => obj.id === child.id)
        if(indexChild > -1) {
          this.listSelectableCat.splice(indexChild, 1)
          this.handleRecursiveCatChildForSplice(child)
        }

        // Handle splice shop
        if(child.shops && child.shops.length > 0) {
          child.shops.forEach(shop => {
            let indexShop = this.listSelectableShop.findIndex(id => id === shop.id)
            if (indexShop > -1) {
              this.listSelectableShop.splice(indexShop, 1)
            }
          })
        }
      })
    },
    /**
     * Handle selectable shop list
     *
     * @param dataEvent
     * @param cat
     */
    getSelectableShop(dataEvent, cat) {
      // If shop input checked was checked
      if(dataEvent.target.checked) {
        this.listSelectableShop.push(parseInt(dataEvent.target.id))
      } else {
        // Handle shop
        let indexShop = this.listSelectableShop.findIndex(id => id === parseInt(dataEvent.target.id))
        if (indexShop > -1) {
          this.listSelectableShop.splice(indexShop, 1)
        }

        // Handle cat
        let indexCat = this.listSelectableCat.findIndex(obj => obj === cat)
        if (indexCat > -1 && dataEvent.target.countElemChecked === 0) {
          this.listSelectableCat.splice(indexCat, 1)
        }
      }

      // Get count of this listSelectableShop
      this.isShopSelectable = this.listSelectableShop.length > 0
    },
    /**
     * Event click button made visual
     */
    eventClickButtonMadeVisual()
    {
      // Show tabs
      this.configExport.showTabs = true
      // Handle Cat
      this.validateConfExportCats()
      // Handle Shops
      this.validateConfExportShops()
      // Handle Stats
      this.validateConfExportStats()
    },
    async eventClickExportCsv() {
      // Display loader
      this.showLoader()

      let model = {
        conf: this.configExport,
        shopsId: this.listSelectableShop
      }

      let filename = ""

      // Fetch export marketing
      await this.$store.dispatch('csvExportAdmin', model)
          .then((response) => {
            filename = response.filename

            // Axios call for download CSV form API
            axios({
              url: response.path,
              method: 'GET',
              responseType: 'blob',
            }).then((response) => {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]))
              var fileLink = document.createElement('a')

              fileLink.href = fileURL
              fileLink.setAttribute(
                  'download',
                  filename
              )
              document.body.appendChild(fileLink)

              fileLink.click();
            })

            this.hideLoader()
          })
      .catch((error) => {
        this.hideLoader()

        this.$bvToast.toast(error.message, {
          title: 'ERREUR !',
          variant: 'danger',
          solid: true
        })
      })
    },
    /**
     * Click on button validate conf export (cat)
     */
    async validateConfExportCats() {
      let that = this

      // Change format date
      this.handleDateConf()

      // Loader
      this.showLoader()

      // Get HTML from API
      exportService.getHtmlExportAdminCats({
        conf : this.configExport,
        shopIds: this.listSelectableShop
      })
        .then((response) => {
          // Set is Generate
          that.html.isHtmlCatGenerate = true

          // Get in var HTML
          this.html.cat = response.data
          this.html.catTemp = response.data

          // Handle tabs index
          this.tabs.tabIndex = 1

          this.hideLoader()
        })
      .catch(() => {
        this.hideLoader()
      })
    },
    /**
     * Click on button validate conf export (shop)
     */
    async validateConfExportShops() {
      let that = this

      // Change format date
      this.handleDateConf()

      // Loader
      this.showLoader()

      // Get HTML from API
      exportService.getHtmlExportAdminShops({
        conf : this.configExport,
        shopIds: this.listSelectableShop
      })
          .then((response) => {
            // Set is Generate
            that.html.isHtmlShopGenerate = true

            // Get in var HTML
            this.html.shop = response.data
            this.html.shopTemp = response.data

            // Handle tabs index
            this.tabs.tabIndex = 1

            this.hideLoader()
          })
          .catch(() => {
            this.hideLoader()
          })
    },
    /**
     * Click on button validate conf export (stats)
     */
    async validateConfExportStats() {
      let that = this

      // Change format date
      this.handleDateConf()

      // Loader
      this.showLoader()

      // Get HTML from API
      await exportService.getHtmlExportAdminStats({
        conf : this.configExport,
        shopIds: this.listSelectableShop
      })
          .then((response) => {
            // Set is Generate
            that.html.isHtmlStatGenerate = true

            // Get in var HTML
            this.html.stats = response.data
            this.html.statsTemp = response.data

            // Handle tabs index
            this.tabs.tabIndex = 1

            // Series chart
            this.apexChart.series = this.setChartSeriesComputed

            this.hideLoader()
          })
          .catch(() => {
            this.hideLoader()
          })
    },
    /**
     * Send the new html to API
     */
    sendHtmlExportCats() {
      // Loader
      this.showLoader()

      let newHtml = this.changeInputToSpanJquery('response-api-html-export-cat');

      exportService.sendHtmlExportAdminCats(newHtml.html())
        .then((response) => {
          let filename = response.data.filename

          // Download PDF (url from API)
          axios({
            url: response.data.path,
            method: 'GET',
            responseType: 'blob',
          }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]))
            var fileLink = document.createElement('a')

            fileLink.href = fileURL
            fileLink.setAttribute(
                'download',
                filename
            )
            document.body.appendChild(fileLink)

            fileLink.click();
          })
          .then(() => {
            // Reset html
            $('.response-api-html-export-cat-temp').html($('.response-api-html-export-cat').html())

            this.hideLoader()
          })
        })
        .catch((error) => {
          // Loader
          this.hideLoader()

          console.log('ERROR : when you try to download PDF categories, plz contact a webMaster !', error)
        })
    },
    async sendHtmlExportShops() {
      let newHtml = this.changeInputToSpanJquery('response-api-html-export-shop');

      this.showLoader()

      await exportService.sendHtmlExportAdminShops(newHtml.html())
          .then((response) => {
            let filename = response.data.filename

            Promise.resolve(
              // Download PDF (url from API)
              axios({
                url: response.data.path,
                method: 'GET',
                responseType: 'blob',
              }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]))
                var fileLink = document.createElement('a')

                fileLink.href = fileURL
                fileLink.setAttribute(
                    'download',
                    filename
                )
                document.body.appendChild(fileLink)

                fileLink.click();
              })
                .then(() => {
                  // Reset html
                  $('.response-api-html-export-shop-temp').html($('.response-api-html-export-shop').html())

                  this.hideLoader()
                })
            )
          })
          .catch((error) => {
            this.hideLoader()
            console.log('ERROR : when you try to download PDF shops, plz contact a webMaster !', error)
          })
    },
    async sendHtmlExportStats() {
      let newHtml = this.changeInputToSpanJquery('response-api-html-export-stats');

      this.showLoader()

      // Chart to base64
      if(this.apexChart.isGenerate) {
        this.apexChart.base64 = await this.$refs.chartStats.chart.dataURI().then(uri => {
          return uri
        })

        // Append in
        $('.response-api-html-export-stats-temp .chart').html('<img src="'+ this.apexChart.base64.imgURI +'" />')
      }

      exportService.sendHtmlExportAdminStats(newHtml.html())
          .then((response) => {
            let filename = response.data.filename

            Promise.resolve(
              // Download PDF (url from API)
              axios({
                url: response.data.path,
                method: 'GET',
                responseType: 'blob',
              }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]))
                var fileLink = document.createElement('a')

                fileLink.href = fileURL
                fileLink.setAttribute(
                    'download',
                    filename
                )
                document.body.appendChild(fileLink)

                fileLink.click();
              })
                  .then(() => {
                    // Reset html
                    $('.response-api-html-export-stats-temp').html($('.response-api-html-export-stats').html())

                    this.hideLoader()
                  })
            )
          })
          .catch((error) => {
            // Loader
            this.hideLoader()

            console.log('ERROR : when you try to download PDF stats, plz contact a webMaster !', error)
          })
    },
    /**
     * Change all input on html request
     */
    changeInputToSpanJquery(idDivExport)
    {
      // INPUT HTML VUE
      let listInput = []
      $('.'+ idDivExport + ' .temp-input').each(function() {
        listInput.push($(this))
      })
      // TEXTAREA HTML
      let listTextArea = []
      $('.'+ idDivExport + ' .temp-textarea').each(function() {
        listTextArea.push($(this))
      })

      // SELECT
      let selectTemp = $('.'+ idDivExport + ' .temp-select')

      // INPUT TO SPAN ON HTML SEND
      $('.'+ idDivExport + '-temp' + ' .temp-input').each(function(indexI) {
        var getClassCss = $(this).attr('class')

        // Handle value negative
        if(idDivExport === 'response-api-html-export-stats' && listInput[indexI].val() < 0) {
          getClassCss += ' text-red'
        }

        var dataType = $(this).attr('data-type')

        // INPUT
        $(this).parent().html("<span class='new-span-input text-center' style='width: 95%'>" + listInput[indexI].val() + "</span>")

        $('.new-span-input').each(function() {
          $(this).attr('class', getClassCss)
          if(dataType && dataType === 'percent' && listInput[indexI].val() && listInput[indexI].val() !== 0) {
            $(this).after("<span class='"+ getClassCss +"'> %</span>")
          }
        })
      })

      // TEXTAREA TO SPAN ON HTML SEND
      $('.'+ idDivExport + '-temp' + ' .temp-textarea').each(function(indexT) {
        var getClassCss = $(this).attr('class')

        if(listTextArea[indexT] !== undefined) {
          $(this).parent().html("<span class='new-span-textarea text-center' style='margin-left: 10px; width: 95%'>" +  ($(this).hasClass('temp-textarea-specific')) ? listTextArea[indexT].val().replace(/\n/g, "<br />") : listTextArea[indexT].val() + "</span>")
        } else {
          $(this).parent().html("<span class='new-span-textarea text-center' style='margin-left: 10px; width: 95%'></span>")
        }

        $('.new-span-textarea').each(function() {
          $(this).attr('class', getClassCss)
        })
      })

      // Get img arrow
      let arrowUp = $('.'+ idDivExport + ' .select-arrow .arrow-up').clone().removeClass('display-none')
      let arrowDown = $('.'+ idDivExport + ' .select-arrow .arrow-down').clone().removeClass('display-none')

      $('.'+ idDivExport + '-temp' + ' .select-arrow .temp-select').remove()
      if(selectTemp.val() === 'up') {
        $('.'+ idDivExport + '-temp' + ' .select-arrow').html(arrowUp)
      } else {
        $('.'+ idDivExport + '-temp' + ' .select-arrow').html(arrowDown)
      }

      return $('.'+ idDivExport + '-temp')
    },
    /**
     * Reset conf export cats
     */
    resetConfExportCats()
    {
      this.$swal({
        title: 'êtes vous sur ?',
        text: "Attention : Si vous acceptez le rechargement, vous perdez vos modifications ! ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2FA76E',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Recharger',
        cancelButtonText: 'Annuler'
      }).then((result) => {

        if (result.value) {

          // Delete form DOM the response (html)
          this.html.cat = ''
          this.html.catTemp = ''

          // Handle tabs index
          this.tabs.tabIndex = 1

          // Get HTML from API
          exportService.getHtmlExportAdminCats({
            conf : this.configExport,
            shopIds: this.listSelectableShop
          })
            .then((response) => {
              this.html.cat = response.data
              this.html.catTemp = response.data
            })
            .catch(() => {
            })
        }
      });
    },
    /**
     * Reset conf export shops
     */
    resetConfExportShops()
    {
      this.$swal({
        title: 'êtes vous sur ?',
        text: "Attention : Si vous acceptez le rechargement, vous perdez vos modifications ! ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2FA76E',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Recharger',
        cancelButtonText: 'Annuler'
      }).then((result) => {

        if (result.value) {
          // Delete form DOM the response (html)
          this.html.shop = ''
          this.html.shopTemp = ''

          // Handle tabs index
          this.tabs.tabIndex = 2

          // Get HTML from API
          exportService.getHtmlExportAdminShops({
            conf : this.configExport,
            shopIds: this.listSelectableShop
          })
            .then((response) => {
              this.html.shop = response.data
              this.html.shopTemp = response.data
            })
        }
      });
    },
    resetConfExportStats() {
      this.$swal({
        title: 'êtes vous sur ?',
        text: "Attention : Si vous acceptez le rechargement, vous perdez vos modifications ! ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2FA76E',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Recharger',
        cancelButtonText: 'Annuler'
      }).then((result) => {

        if (result.value) {
          // Delete form DOM the response (html)
          this.html.stats = ''
          this.html.statsTemp = ''

          // Handle tabs index
          this.tabs.tabIndex = 3

          // Get HTML from API
          exportService.getHtmlExportAdminStats({
            conf : this.configExport,
            shopIds: this.listSelectableShop
          })
              .then((response) => {
                this.html.stats = response.data
                this.html.statsTemp = response.data

                // Series chart
                this.apexChart.series = this.setChartSeriesComputed
              })
        }
      });
    },
    /**
     * Reset total conf
     */
    resetConf()
    {
      // Reset var
      this.configExport = {
          date: null,
          isOk: false,
          showShop: false
      }

      this.selectableCat = null
      this.listSelectableShop = []
      this.listSelectableCat =  []

      // Remove html
      this.html = {
        cat: '',
        shop: '',
        isHtmlCatGenerate: false,
        isHtmlShopGenerate: false
      }

      // Checkbox
      this.isAllOpen = false

      // tabs
      this.tabs.tabIndex = 0

      // jquery
      $('.form-check-input').prop('checked', false);
    },
    /**
     * Event click on tabs
     */
    handleTabIndex(index) {
      this.tabs.tabIndex = index

      if(index === 1) {
        this.tabs.isLoadingCat = true
        setTimeout(()=> {
          this.tabs.isLoadingCat = false
        },1000)
      } else if(index === 2) {
        this.tabs.isLoadingShop = true
        setTimeout(()=> {
          this.tabs.isLoadingShop = false
        },1000)
      } else if(index === 3) {
        this.tabs.isLoadingStats = true

        // Handle chart
        this.apexChart.series = this.generateChart()

        setTimeout(()=> {
          this.tabs.isLoadingStats = false
        },1000)
      }
    },
    changeConfig() {
      if(this.configExport.isOk) {
        this.$swal({
          title: 'Vous êtes entrain de changer votre configuration',
          text: "Attention : Si vous acceptez le changement de configuration, Les rendus visuels vont être rechargé, vos perdez vos modifications ! ",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#2FA76E',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Changer de configuration ?',
          cancelButtonText: 'Annuler'
        }).then((result) => {

          if (result.value) {
            // Delete form DOM the response (html)
            this.html.shop = ''
            this.html.shopTemp = ''
            this.html.cat = ''
            this.html.catTemp = ''
            this.html.stats = ''
            this.html.statsTemp = ''

            // Handle tabs index
            this.tabs.tabIndex = 1

            // Get HTML from API (cats)
            exportService.getHtmlExportAdminCats({
              conf: this.configExport,
              shopIds: this.listSelectableShop
            })
              .then((response) => {
                this.html.cat = response.data
                this.html.catTemp = response.data
              })
            // Get HTML from API (shop)
            exportService.getHtmlExportAdminShops({
              conf: this.configExport,
              shopIds: this.listSelectableShop
            })
              .then((response) => {
                this.html.shop = response.data
                this.html.shopTemp = response.data
              })
            // Get HTML from API (stats)
            exportService.getHtmlExportAdminStats({
              conf: this.configExport,
              shopIds: this.listSelectableShop
            })
                .then((response) => {
                  this.html.stats = response.data
                  this.html.statsTemp = response.data

                  // Series chart
                  this.apexChart.series = this.setChartSeriesComputed
                })
          }
        });
      }
    },
    /*----------------- APEX CHART --------------------*/
    generateChart() {
      this.apexChart.isGenerate = true

      // Date
      let dateRequested = moment(this.configExport.date)
      let subtractOneYear = new Date(moment(dateRequested).subtract(1, 'years'))
      let dateOneLessYear = moment(subtractOneYear)

      let startDate = moment(dateOneLessYear.format('YYYY') + '-01-01')
      let endDate = moment(dateRequested.format('YYYY') + '-12-31')

      // init var
      let listEvolutionGross = []
      let listEvolutionCompare = []
      let listEvolutionCncc = []

      // Evolution Gross
      let valueEvolutionGrossCurrent = this.getInputValueInTable(dateRequested, 'evolution-gross')
      let valueEvolutionGrossOneLessYear = this.getInputValueInTable(dateOneLessYear, 'evolution-gross')

      // Array merge
      let resultEvolutionGross = valueEvolutionGrossOneLessYear.concat(valueEvolutionGrossCurrent)

      // Evolution Compare
      let valueEvolutionCompareCurrent = this.getInputValueInTable(dateRequested, 'evolution-compare')
      let valueEvolutionCompareOneLessYear = this.getInputValueInTable(dateOneLessYear, 'evolution-compare')
      // Array merge
      let resultEvolutionCompare = valueEvolutionCompareOneLessYear.concat(valueEvolutionCompareCurrent)
      // CNCC
      let valueCnccCurrent = this.getInputValueInTable(dateRequested, 'cncc')
      let valueCnccOneLessYear = this.getInputValueInTable(dateOneLessYear, 'cncc')
      // Array merge
      let resultCncc = valueCnccOneLessYear.concat(valueCnccCurrent)

      // For loop between start and end day
      var i = 0
      while (endDate > startDate || startDate.format('M') === endDate.format('M')) {

        let modelEvolutionGross = {
          x: startDate.format('MM-YYYY'),
          y: resultEvolutionGross[i]
        }

        let modelEvolutionCompare = {
          x: startDate.format('MM-YYYY'),
          y: resultEvolutionCompare[i]
        }

        let modelCNCC = {
          x: startDate.format('MM-YYYY'),
          y: resultCncc[i]
        }

        // Push in list
        listEvolutionGross.push(modelEvolutionGross)
        listEvolutionCompare.push(modelEvolutionCompare)
        listEvolutionCncc.push(modelCNCC)

        i++
        startDate.add(1, 'months')
      }

      // Set series apex chart
      return [
        {
          name: "Evolution CA Brut",
          data: listEvolutionGross,
        },
        {
          name: "Evolution CA Prorata",
          data: listEvolutionCompare,
        },
        {
          name: "Evolution indice CNCC",
          data: listEvolutionCncc,
        }
      ]
    },
    resetChart() {
      this.apexChart.series = this.generateChart()
    },
    getInputValueInTable(dateRequested, cssClass)
    {
      // get data from table
      let rowTable = $('.response-api-html-export-stats .table-stats-'+ dateRequested.format('YYYY') + ' .' + cssClass)

      let listInputValue = []
      // get value from input on second TR
      rowTable.find('td').each(function(index) {
        let inputVal = $(this).find('input').val()

        if(inputVal !== undefined && index !== 13) {
          listInputValue.push(inputVal)
        }
      })

      return listInputValue
    },
    ChangeTempInput(e) {
      if(e.target.matches('.temp-input')) {
        this.apexChart.series = this.generateChart()
      }
    }
  },
  watch: {
    setChartSeriesComputed: function(newValue)
    {
      this.apexChart.series = newValue
    },
    /**
     * Check if conf date and selectableShop was correctly fill
     * @param confValue
     */
    configProperty(confValue) {
      this.configExport.isOk = confValue[0] != null && confValue[1] !== 0

      if(confValue[0] != null && confValue[1] !== 0)
      {
        this.configExport.showShop = true
      }
    },
  },
  computed: {
    setChartSeriesComputed() {
      return this.generateChart()
    },
    configProperty() {
      return [this.configExport.date, this.listSelectableShop.length]
    },
    ...mapState(['CategoryModule']),
    ...mapState(['ShopModule']),
    ...mapState(['ExportModule']),
    ...mapState(['UserModule'])
  },
  components: {
    Isotope: () => import('vueisotope'),
    Datepicker: () => import('vuejs-datepicker'),
    NodeTree: () => import('@/components/_shared/NodeTree'),
    ApexChart: () => import('vue-apexcharts'),
    Loading: () => import('vue-loading-overlay')
  }
}
</script>
