<template>
  <div class="row h-100" id="page-forgot-password">

    <div class="col-4 m-auto">
      <h2 class="text-center mb-5">Demande de mot de passe oublié</h2>

      <div class="form-group">
        <input class="form-control" id="email" v-model="form.email" type="email" :placeholder="placeholderInput">
      </div>

      <div class="text-center mt-3 mb-3">
        <a class="m-auto" href="/">Retour à la page de connexion ?</a>
      </div>

      <button class="btn btn-lg btn-block btn-success" v-on:click="requestForgotPassword">
        {{ valueButtonSubmit }}
      </button>
    </div>
  </div>
</template>

<script>
import PublicService from '@/services/PublicService'

export default {
  data() {
    return {
      placeholderInput: 'votre e-mail',
      valueButtonSubmit: 'Envoyer la demande de mot de passe oublié',
      form: {
        email: ''
      }
    }
  },
  created() {
    this.$store.dispatch('logout')
  },
  methods: {
    requestForgotPassword()
    {
      if(!this.form.email) {
        this.$bvToast.toast('Merci de remplir le champ e-mail', {
          title: 'ERREUR !',
          variant: 'danger',
          solid: true
        })
      } else {
        PublicService.checkUserByEmail( {email: this.form.email})
          .then((response) => {
            if(response.data.isExist) {

              // Send Mail
              PublicService.sendForgotPassword(response.data.user.id)
                .then(() => {
                  this.$bvToast.toast('Votre demande de renouvellement de mot de passe à été envoyé, vérifier votre boite mail', {
                    title: 'Demande de renouvellement de mot passe',
                    variant: 'success',
                    solid: true
                  })
                })

            } else {
              this.$bvToast.toast('Aucun compte actif ne correspond à ce mail : ' + this.form.email, {
                title: 'ERREUR !',
                variant: 'danger',
                solid: true
              })
            }
          })
          .catch((error) => {
            this.$bvToast.toast(error.message, {
              title: 'ERREUR !',
              variant: 'danger',
              solid: true
            })
          })
      }
    }
  },
  computed: {
  }
}
</script>
