<template>
  <div id="app">
    <Header v-if="this.$route.name && $store.getters.loggedIn" class="mobile" :show-sidebar="showSidebar" @click-event-burger="handleClickEventBurger"/>
    <div class="h-100" v-if="$store.getters.loggedIn && this.$route.name">
      <div class="wrapper d-flex w-100" v-if="$store.getters.loggedIn">
        <SideBar
            class="sidebar"
            v-if="$store.getters.loggedIn && ($store.getters.userIsAdmin || $store.getters.userIsMarketing) && this.$route.name"
            :class="(showSidebar) ? '' : 'collapsed'"
            :isMobile="isMobile"
            @click-event-link="handleClickLinkMobile"
            @resize-collapsed="handleCollapsedResize"
        />
        <div class="main" :class="($store.getters.userIsShop) ? 'main-shop' : ''">
          <Header v-if="this.$route.name" :show-sidebar="showSidebar" @click-event-burger="handleClickEventBurger"/>
          <div class="app-container position-relative pl-2 pl-md-5 pr-2 pr-md-5 pb-2 pb-md-5 d-flex flex-column">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="h-100">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showSidebar: null,
      isMobile: false
    }
  },
  methods: {
    /**
     * Emit click from header
     */
    handleClickEventBurger(model) {
      this.showSidebar = model.showSidebar
      this.isMobile = model.isMobile
    },
    /**
     * Event click link sidebar (mobile)
     * Close menu
     */
    handleClickLinkMobile() {
      this.showSidebar = !this.showSidebar
    },
    handleCollapsedResize(model) {
      this.showSidebar = model.showSidebar
    }
  },
  components: {
    Header: () => import('@/components/layouts/Header'),
    SideBar: () => import('@/components/layouts/SideBar')
  },
}

</script>
