<template>
  <div id="page-shop-edit">
    {{ /* Heading page */ }}
    <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
      <div class="text-center">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
    <div v-else>
      <div class="header-pages-block d-md-flex justify-content-between mb-5">
        <div>
          <h5>{{ dataPage.title }} <strong>{{ ShopModule.shop.name }}</strong></h5>
          <h5>Centre : <strong>{{ (ShopModule.shop.center) ? ShopModule.shop.center.name : '' }}</strong></h5>
        </div>
        <div>
          <router-link
              class="btn btn-outline-primary mt-2 mt-md-0"
              :to="{ name: 'shop-list' }"
          >
            retour
          </router-link>
        </div>
      </div>

      {{ /* Form component */ }}
      <FormCrudShop
          :shop="ShopModule.shop"
          :id="ShopModule.shop.id"
          :actionForm="'edit'"
          :selected-company="getSelectedCompany"
          :selected-category="getSelectedCategory"
          :selected-date-limit-sale="getSelectedDateLimitSale"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      loader: {
        isLoading: false,
      },
      dataPage: {
        title: 'Edition du commerce : '
      },
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  async created() {
    this.showLoader()

    let model = {
      centerId: this.$store.getters.getChannel.center.id,
      shopId: this.$route.params.id
    }

    await this.$store.dispatch('fetchShop', model)
      .then(() => {
        setTimeout(function () { this.hideLoader() }.bind(this), 250)
      })
  },
  computed: {
    /**
     * Set var selectedCompany
     *
     * @returns {*|number}
     */
    getSelectedCompany()
    {
      return (this.ShopModule.shop && this.ShopModule.shop.company) ? this.ShopModule.shop.company.id : null
    },
    /**
     * Set var selectedCategory
     *
     * @returns {*|number}
     */
    getSelectedCategory()
    {
      return (this.ShopModule.shop && this.ShopModule.shop.category) ? this.ShopModule.shop.category.id : null
    },
    /**
     * Set var selectedDateLimitSale
     *
     * @returns {*|number}
     */
    getSelectedDateLimitSale()
    {
      return (this.ShopModule.shop && this.ShopModule.shop.dateLimitSale) ? this.ShopModule.shop.dateLimitSale : null
    },
    ...mapState(['ChannelModule']),
    ...mapState(['ShopModule'])
  },
  components: {
    FormCrudShop: () => import('@/components/pages/shop/FormCrudShop'),
  }
}
</script>
