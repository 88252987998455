import AxiosService from '@/services/AxiosService'
import UrlBuilder from '@/helper/urlBuilder'

export default {
  /**
   * Get list of user
   *
   * @param centerId => current center
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @returns {*}
   */
  getUsers(centerId, perPage, page, order, search) {
    return AxiosService.callApi().get(UrlBuilder('users', centerId, {
      perPage : perPage,
      page: page,
      order: order,
      search: search
    }))
  },
  /**
   * Get user by id
   *
   * @param centerId
   * @param userId
   * @returns {*}
   */
  getUser(centerId, userId) {
    return AxiosService.callApi().get('/' + centerId + '/users/' + userId)
  },
  /**
   * Create User
   *
   * @param centerId
   * @param data
   * @returns {*}
   */
  create(centerId, data) {
    return AxiosService.callApi().post('/' + centerId + '/users', data)
  },
  /**
   * Edit User
   *
   * @param centerId
   * @param id
   * @param data
   * @returns {*}
   */
  edit(centerId, id, data) {
    return AxiosService.callApi().put('/' + centerId + '/users/' + id, data)
  },
  /**
   * Edit User is Active
   *
   * @param centerId
   * @param id
   * @param data
   * @returns {*}
   */
  editIsActive(centerId, id, data) {
    return AxiosService.callApi().put('/' + centerId + '/users/active/' + id, data)
  }
}
