import CategoryService from '@/services/CategoryService'

export default {
  state: {
    category: {},
    categories: [],
    parentCategories: [],
    exportParentCategories: []
  },
  mutations: {
    ADD_CATEGORY(state, category) {
      state.categories.push(category)
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    SET_PARENT_CATEGORIES(state, parentCategories) {
      state.parentCategories = parentCategories
    },
    SET_EXPORT_PARENT_CATEGORIES(state, exportParentCategories) {
      state.exportParentCategories = exportParentCategories
    },
    SET_CATEGORY(state, category) {
      state.category = category
    }
  },
  actions: {
    /**
     * Fetch in state list of categories
     *
     * @param commit
     */
    fetchCategories({ commit }) {
      CategoryService.getCategories()
        .then(response => {
          commit('SET_CATEGORIES', response.data.list)
        })
        .catch(error => {
          console.log('There was an error on category list: ', error)
        })
    },
    /**
     * Fetch in state list of parent categories
     */
    fetchParentCategories({ commit }) {
      CategoryService.getParentCategories()
        .then(response => {
          commit('SET_PARENT_CATEGORIES', response.data)
        })
        .catch(error => {
          console.log('There was an error on category parent list: ', error)
        })
    },
    /**
     * Fetch in state list of export parent categories
     */
    fetchExportParentCategories({ commit }) {
      CategoryService.getExportParentCategories()
        .then(response => {
          commit('SET_EXPORT_PARENT_CATEGORIES', response.data)
        })
        .catch(error => {
          console.log('There was an error on category export parent list: ', error)
        })
    },
    /**
     * Fetch in state a category
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchCategory({ commit }, id)
    {
      CategoryService.getCategory(id)
        .then(response => {
          commit('SET_CATEGORY', response.data)
        })
        .catch((error) => {
          console.log('There was an error on category: ', error)
        })
    },
    /**
     * Create category
     *
     * @param commit
     * @param category
     */
    createCategory({ commit }, category)
    {
      return CategoryService.create(category)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_CATEGORY', response.data)
          return Promise.resolve()
        })
    },
    /**
     * Update category
     *
     * @param commit
     * @param id
     * @param category
     * @returns {*}
     */
    updateCategory({ commit }, {id, category})
    {
      return CategoryService.edit(id, category)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_CATEGORY', response.data.list)
        })
    }
  },
  getters: {
    /**
     * Get category by id into list load by fetchCategories
     *
     * @param state
     * @returns {function(*): * | undefined}
     */
    getCategoryById: state => id => {
      return state.categories.find(category => category.id === id)
    },
    /**
     * Get category by id into list load by fetchCategories
     *
     * @param state
     * @returns {function(*): * | undefined}
     */
    getExportParentCategoryById: state => id => {
      return state.exportParentCategories.find(category => category.id === id)
    },
    /**
     * Get category by id into list load by fetchCategories
     *
     * @param state
     * @returns {function(*): * | undefined}
     */
    getExportCategoryById: state => (parentId, id) => {
      const parent = state.exportParentCategories.find(category => category.id === parentId)
      return parent.childs.find(child => child.id === id)
    },
    /**
     * Get all parent Cat
     * @returns {[]|*}
     */
    getAllParentCat() {
      return this.parentCategories
    }
  }
}
