<template>
  <div id="page-dashboard">
    <div v-if="this.$store.getters.userIsAdmin">

      {{ /* Heading page */ }}
      <div class="header-pages-block d-xl-flex justify-content-xl-between mb-xl-0">
        <h3 class="p-0">
          <b-icon class="mr-md-3" icon="bar-chart-line" font-scale="1.5" variant="primary"></b-icon> Visualisation des chiffres d'affaires par commerce
        </h3>

        <div class="mt-lg-3 mt-xl-0 p-0">
          <div class="d-flex w-100 p-2">
            <b-icon
                v-on:click="eventToggleDatePeriod"
                class="button-period mr-3 p-2"
                :class="(!toggleDatePeriod) ? '' : ''"
                icon="chevron-left"
                font-scale="2.5"
                variant="secondary"
            ></b-icon>

            <div class="text-period p-2 w-100 text-center">
              {{
                (!toggleDatePeriod)
                ? getMonthHistory().monthLessOne.dateStr
                : getMonthHistory().monthLessTwo.dateStr | capitalize
              }}
            </div>

            <b-icon
                v-on:click="eventToggleDatePeriod"
                class="button-period ml-3 p-2"
                :class="(toggleDatePeriod) ? '' : ''"
                icon="chevron-right"
                font-scale="2.5"
                variant="secondary"
            ></b-icon>
          </div>
        </div>
      </div>

      <hr/>

      <div class="d-xl-flex justify-content-xl-between mt-4 mt-xl-0 mb-4">
        <div class="ml-0 ml-md-3 mt-lg-5 mt-xl-3">
          Nombre de commerce(s): {{ DashboardModule.shopsDashboard.total }}
        </div>

        <div class="filter-up-to-date ">
          <div class="d-flex mt-1">
            <b-form-checkbox
                switch size="lg"
                class="ml-4 mt-2"
                v-model="filter.isLate"
                v-on:change="eventChangeFilterIsLate()"
            >
              En retard
            </b-form-checkbox>
            <b-form-checkbox
                switch
                size="lg"
                class="mt-2 ml-4"
                v-model="filter.isUpToDate"
                v-on:change="eventChangeFilterIsUpToDate()"
            >
              A jour
            </b-form-checkbox>
          </div>
        </div>

        <div class="search mr-md-3 mt-4 mt-xl-2">
          <div class="input-group">
            <input type="text" class="border-0 form-control border" v-model="filter.search" placeholder="Mots-clés" v-on:keyup.enter="sendResearchRequest">
            <b-icon class="icon-x" icon="x" font-scale="1.5" v-on:click="sendResearchRequestReset"></b-icon>
            <div class="input-group-append">
              <button class="btn btn-success p-0 pl-2 pr-2" v-on:click="sendResearchRequest">
                Rechercher
              </button>
            </div>
          </div>
        </div>
      </div>

      {{ /* Cards */ }}
      <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
        <div class="text-center">
          <b-spinner variant="success" label="Spinning"></b-spinner>
          <div class="text-primary">Chargement en cours ...</div>
        </div>
      </div>
      <div v-else>
        <Isotope
            :list="dashboardList"
            :options="isotope.options"
            class="iso-default"
        >
          <div class="ml-2 mt-2" v-for="(obj, index) in dashboardList" :key="index">
            <div class="card card-isotope-dashboard border-top-0 app-box-shadow" :class="(obj.isUpToDate) ? 'bg-success text-light' : 'bg-danger text-light'">
              <div class="card-body">
                <div class="text-center shop-title mb-4">
                  <router-link
                      class="text-light"
                      :to="{ name: 'shop-show', params: {'id' : obj.shop.id }, query: { isDashboard: 'true' }}">
                    <b>{{ obj.shop.name }}</b>
                  </router-link>
                </div>
                <div class="m-auto">
                  <div class="text-center small-text text-light mt-2">
                    Date limite CA: {{ obj.shop.dateLimitSale }}
                  </div>
                  <div class="text-center medium-text mt-2">
                    <a :href="'mailto:' + obj.shop.contactEmail"><span class="ml-2 text-light">{{ obj.shop.contactEmail }}</span></a>
                  </div>
                  <div v-if="obj.shop.contactPhone" class="text-center medium-text mt-2">
                    <span class="ml-2 text-light">{{ obj.shop.contactPhone }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Isotope>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      loader: {
        isLoading: false
      },
      isotope: {
        options: {
        }
      },
      dashboardList: [],
      filter: {
        isLate: false,
        isUpToDate: false,
        search: null,
      },
      toggleDatePeriod: false,
      bootstrapPaginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
      },
      dataPage: {
        title: 'Liste des commerces',
        dropDownName: ''
      },
      configFilter: {
        headerList : [
          {'index' : 'id', 'name' : 'ID', 'sort': 'ASC' },
          {'index' : 'id', 'name' : 'ID', 'sort': 'DESC' },
          {'index' : 'name', 'name' : 'Nom', 'sort': 'ASC' },
          {'index' : 'name', 'name' : 'Nom', 'sort': 'DESC' },
          {'index' : 'vatRate', 'name' : 'Taux de TVA', 'sort': 'ASC' },
          {'index' : 'vatRate', 'name' : 'Taux de TVA', 'sort': 'DESC' },
          {'index' : 'openDate', 'name' : 'Date d\'ouverture', 'sort': 'ASC' },
          {'index' : 'openDate', 'name' : 'Date d\'ouverture', 'sort': 'DESC' },
          {'index' : 'dateLimitSale', 'name' : 'Date limite de CA', 'sort': 'ASC' },
          {'index' : 'dateLimitSale', 'name' : 'Date limite de CA', 'sort': 'DESC' },
          {'index' : 'contactEmail', 'name' : 'Email du contact', 'sort': 'ASC'},
          {'index' : 'contactEmail', 'name' : 'Email du contact', 'sort': 'DESC'},
        ]
      }
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    async callApiDashboard()
    {
      this.showLoader()

      let params = {
        centerId: this.$store.getters.getChannel.center.id,
        date: {
          month : (this.toggleDatePeriod) ? this.getMonthHistory().monthLessTwo.monthNumber : this.getMonthHistory().monthLessOne.monthNumber ,
          year: (this.toggleDatePeriod) ? this.getMonthHistory().monthLessTwo.yearNumber : this.getMonthHistory().monthLessOne.yearNumber
        }
      }

      await this.$store.dispatch('fetchDashboardShops',  params)
        .then((response) => {
            this.dashboardList = response.listDashboard

            this.hideLoader()
        })
    },
    /**
     * Toggle period
     */
    eventToggleDatePeriod()
    {
      this.toggleDatePeriod = !this.toggleDatePeriod

      this.filter.isLate = false
      this.filter.isUpToDate = false

      this.callApiDashboard()
    },
    eventChangeFilterIsLate() {
      if(this.filter.isLate) {
        if(this.filter.isUpToDate) {
          this.filter.isUpToDate = false
          this.dashboardList = this.DashboardModule.shopsDashboard.listDashboard
        }

        this.dashboardList = this.filterIsLate
      } else {
        this.dashboardList = this.DashboardModule.shopsDashboard.listDashboard
      }
    },
    eventChangeFilterIsUpToDate() {
      if(this.filter.isUpToDate) {
        if(this.filter.isLate) {
          this.filter.isLate = false
          this.dashboardList = this.DashboardModule.shopsDashboard.listDashboard
        }

        this.dashboardList = this.filterIsUpToDate
      } else {
        this.dashboardList = this.DashboardModule.shopsDashboard.listDashboard
      }
    },
    /**
     * Reset research
     */
    sendResearchRequestReset()
    {
      this.filter.search = null
      this.dashboardList = this.DashboardModule.shopsDashboard.listDashboard
    },
    /**
     * Send request for research
     */
    sendResearchRequest()
    {
      this.dashboardList = this.searchFilterList
    },
    /**
     * Handle m-1 and m-2
     * @returns {{monthLessTwo: {dateStr: *, monthNumber: *, yearNumber: *}, monthLessOne: {dateStr: *, monthNumber: *, yearNumber: *}}}
     */
    getMonthHistory()
    {
      let currentDate = moment().format("YYYY-MM-01")

      return {
        monthLessOne : {
          dateStr: moment(currentDate).subtract(1, 'month').format('MMMM YYYY'),
          monthNumber: moment(currentDate).subtract(1, 'month').format('MM'),
          yearNumber: moment(currentDate).subtract(1, 'month').format('YYYY'),
        },
        monthLessTwo : {
          dateStr : moment(currentDate).subtract(2, 'month').format('MMMM YYYY'),
          monthNumber: moment(currentDate).subtract(2, 'month').format('MM'),
          yearNumber: moment(currentDate).subtract(2, 'month').format('YYYY'),
        }
      }
    },
  },
  async created() {
    await this.callApiDashboard()

    this.DashboardModule.shopsDashboard.listDashboard
    this.dashboardList = this.DashboardModule.shopsDashboard.listDashboard
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  computed: {
    searchFilterList() {
      if(this.dashboardList) {
        return this.dashboardList.filter(item => {
          return item.shop.name.match(this.filter.search.toUpperCase())
        })
      }

      return []
    },
    filterIsLate() {
      if(this.dashboardList) {
        return this.dashboardList.filter(item => {
          return item.isUpToDate === false
        })
      }

      return []
    },
    filterIsUpToDate() {
      if(this.dashboardList) {
        return this.dashboardList.filter(item => {
          return item.isUpToDate === true
        })
      }

      return []
    },
    ...mapState(['ChannelModule']),
    ...mapState(['DashboardModule'])
  },
  components: {
    Isotope: () => import('vueisotope')
  }
}
</script>
