<template>
  <div id="page-shop-event" class="mb-5">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-4">
      <div>
        <h5>{{ dataPage.title }}</h5>
        <h5>Commerce : <strong>{{ (ShopModule.shop) ? ShopModule.shop.name : '' }}</strong></h5>
      </div>
      <div>
        <router-link
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'shop-list' }"
        >
          retour
        </router-link>
      </div>
    </div>

    <hr />

    <div class="d-md-flex justify-content-between">

      {{ /* FORM */ }}
      <div class="col-12 col-md-4 mt-2 p-0 flex-grow-1">
        <div class="card">
          <div class="card-body pb-0">
            <h5>{{ dataPage.titleCardForm }}</h5>
            <hr />

            <FormCrudShopEvent
                :shop="ShopModule.shop"
                :events="EventModule.events"
                :listShopEvents="listShopEvents"
                :shopEvent="shopEvent"
                :shop-event-id="shopEventId"
                :selected-type-event="shopEventTypeId"
                :action-form="actionForm"
                :count-datepicker="(shopEvent.listDateRemoveSales.length > 0) ? shopEvent.listDateRemoveSales.length : 1"
                :value-button-submit="dataPage.valueButtonSubmit"
                @created="newShopEvents"
                @edited="updateShopEvents"
                @cancel="cancelEditMode"
            >
            </FormCrudShopEvent>
          </div>
        </div>
      </div>

      {{ /* CARD */ }}
      <div class="col-12 col-md-8 p-0 mt-4 mt-md-0">
        <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
          <div class="text-center">
            <b-spinner variant="success" label="Spinning"></b-spinner>
            <div class="text-primary">Chargement en cours ...</div>
          </div>
        </div>
        <div v-else>
          <div class="col-12 p-0 d-md-flex flex-md-wrap justify-content-between" v-if="ShopEventModule.shopEvents.length > 0">
            <div v-for="(shopEvent, index) in ShopEventModule.shopEvents" :key="index">
              <div class="card app-box-shadow p-4 card-event ml-md-4 mt-2" :class="(activeCardEvent === index) ? 'border-primary': 'border-0'">
                <div class="text-center">
                  <h5>Evénement du <b>{{ shopEvent.startDate | moment('DD/MM/YYYY') }}</b> au <b>{{ shopEvent.endDate | moment('DD/MM/YYYY')}}</b></h5>
                </div>

                <div class="card-body text-center">
                  <p>
                    <b>Type d'événement</b>
                    <br />
                    {{ (shopEvent.event) ? shopEvent.event.name : '' }}
                  </p>
                  <p>
                    <b>Raison</b>
                    <br />
                    {{ shopEvent.comment }}
                  </p>
                  <div v-if="shopEvent.shopsEventDate.length > 0">
                    <b>Liste des dates retirées du chiffre d'affaire</b>
                    <br />
                    <div class="" v-for="(obj, index) in shopEvent.shopsEventDate" :key="index">
                      {{ obj.date| moment('DD/MM/YYYY') }}
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-center">
                  <button class="btn btn-outline-danger" v-on:click="clickButtonDelete(shopEvent.id, index)">Supprimer</button>
                  <button class="btn btn-primary ml-2" v-on:click="clickButtonUpdate(shopEvent.id, index)">Editer</button>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="ml-4 mt-1 text-center">
            Aucun événement, merci de remplir le formulaire à votre gauche pour créér un nouvel événément
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      listShopEvents: [],
      shopEventId: null,
      shopEventTypeId: null,
      actionForm: 'create',
      activeCardEvent: null,
      shopEvent: {
        shop: Number(this.$route.params.id),
        event: null,
        startDate: null,
        endDate: null,
        comment: null,
        listDateRemoveSales: []
      },
      loader: {
        isLoading: false
      },
      displayFormCreate: false,
      dataPage: {
        titleCardForm: 'Créer un événement',
        valueButtonSubmit : 'Créer',
        title: 'Gestion des événements'
      }
    }
  },
  methods: {
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    handleClickActionForm() {
      this.actionForm = 'create'
      this.activeCardEvent = null
      this.dataPage.titleCardForm = 'Créer un événement'
      this.dataPage.valueButtonSubmit = 'Créer un événement'

      // Reset shopEvent
      this.resetShopEvent()
    },
    /**
     * Emit on created a new Event
     */
    newShopEvents()
    {
      // Display loader
      this.showLoader()
      // Reset shop event
      this.shopEvent = {
        shop: Number(this.$route.params.id),
        event: null,
        startDate: null,
        endDate: null,
        comment: null,
        listDateRemoveSales: []
      }
      // Fetch list with new event
      this.$store.dispatch('fetchShopEvents', {
        centerId: this.$store.getters.getChannel.center.id,
        shopId: this.$route.params.id
      })
        .then(() => {
          this.hideLoader()
        })
    },
    /**
     * Update shop Event
     */
    updateShopEvents()
    {
      // Handle page
      this.activeCardEvent = null
      this.dataPage.titleCardForm = 'Créer un événement'
      this.dataPage.valueButtonSubmit = 'Créer un événement'
      this.actionForm = 'create'

      // Display loader
      this.showLoader()

      // Fetch list with event updated
      this.$store.dispatch('fetchShopEvents', {
        centerId: this.$store.getters.getChannel.center.id,
        shopId: this.$route.params.id
      })
        .then(() => {
          this.hideLoader()
        })

      // Reset shopEvent
      this.resetShopEvent()
    },
    cancelEditMode()
    {
      this.actionForm = 'create'
      this.activeCardEvent = null
      this.shopEvent = {
        shop: Number(this.$route.params.id),
        event: null,
        startDate: null,
        endDate: null,
        comment: null,
        listDateRemoveSales: []
      }

      // Handle form
      this.dataPage.titleCardForm = 'Créer un événement'
      this.dataPage.valueButtonSubmit = 'Créer'
    },
    /**
     * Update a shop Event
     *
     * @param id
     * @param index
     */
    clickButtonUpdate(id, index)
    {
      // top of window
      window.scrollTo(0,0);

      // class active
      this.activeCardEvent = index

      // Get shopEvent selected
      this.shopEvent = this.$store.getters.getShopEventById(id)

      // Handle list removes sales
      let list = []

      this.$store.getters.getShopEventById(id).shopsEventDate.forEach((obj, index) => {
        let model = {
          index: index,
          date: obj.date
        }

        list.push(model)
      })

      this.shopEvent.listDateRemoveSales = list

      // Set if for form
      this.shopEventId = id
      this.shopEventTypeId = this.$store.getters.getShopEventById(id).event.id

      // Handle form
      this.dataPage.titleCardForm = 'Editer un événement'
      this.dataPage.valueButtonSubmit = 'Editer'
      this.displayFormCreate = true
      this.actionForm = 'edit'
    },
    /**
     * Delete shop event
     *
     * @param id
     * @param index
     */
    clickButtonDelete(id, index)
    {
      this.$swal({
        title: 'Voulez vous vraiment supprimer cet événement ?',
        text: "Attention : Cette action est iréversible ! ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Supprimer',
        cancelButtonText: 'Annuler',
      }).then((result) => {

        if (result.value) {
          this.showLoader()

          // Send to API
          this.$store.dispatch('deleteShopEvent', {
            centerId: this.$store.getters.getChannel.center.id,
            id: id,
            index: index
          })
            .then(() => {
              this.$bvToast.toast('La suppresion de l\'événement à été effectué avec succès', {
                title: 'Suppression Evénement',
                variant: 'success',
                solid: true
              })

              this.hideLoader()
            })
        }
      });
    },
    /**
     * Reset OBJ shopEvent
     */
    resetShopEvent()
    {
      this.shopEvent = {
        shop: Number(this.$route.params.id),
        event: null,
        startDate: null,
        endDate: null,
        startDateRemoveSale: null,
        endDateRemoveSale: null,
        comment: null,
        listDateRemoveSales: []
      }
    }
  },
  async created() {
    // loader
    this.showLoader()

    // Call data From API
    this.$store.dispatch('fetchShop', {
      centerId: this.$store.getters.getChannel.center.id,
      shopId: this.$route.params.id
    })
    this.$store.dispatch('fetchShopEvents', {
      centerId: this.$store.getters.getChannel.center.id,
      shopId: this.$route.params.id
    })
      .then(() => { this.hideLoader() })

    this.$store.dispatch('fetchEvents')

    // Set data
    this.listShopEvents = this.ShopEventModule.shopEvents
  },
  computed: {
    getSelectedTypeEvent() {
      return (this.ShopEventModule.shopEvents && this.ShopEventModule.shopEvents.event) ? this.ShopEventModule.shopEvents.event.id : null
    },
    ...mapState(['ChannelModule']),
    ...mapState(['ShopModule']),
    ...mapState(['ShopEventModule']),
    ...mapState(['EventModule']),
  },
  components: {
    FormCrudShopEvent: () => import('@/components/pages/shop/FormCrudShopEvent')
  }
}
</script>

