<template>
  <div id="page-sale">
    {{ /* HEADING PAGE FOR ADMIN */ }}
    <div v-if="this.$store.getters.userIsAdmin" class="header-pages-block d-md-flex justify-content-between">
      <div class="">
        <h3 class="">
          <b-icon class="mr-3" icon="bar-chart-line" font-scale="1.5" variant="primary"></b-icon>
          {{ (this.$store.getters.userIsAdmin) ? 'Chiffres d\'affaires : ' + SaleModule.shop.name : 'Vos chiffres d\'affaires' }}
        </h3>
        <div v-if="this.$store.getters.userIsAdmin && !SaleModule.shop.isMonth">
          Le magasin a jusqu'au {{ getDateLimitPeriod(SaleModule.shop.dateLimitSale, 'DD MMMM YYYY') }} pour remplir son chiffre d'affaires
        </div>
      </div>

      <div class="mt-4">
        <router-link
            class="btn btn-outline-primary"
            :to="{ name: 'shop-list' }"
        >
          retour
        </router-link>
      </div>
    </div>

    <hr class="mt-5" v-if="this.$store.getters.userIsAdmin"/>

    <div v-if="SaleModule.shop.doubleSaleEntry && !this.$store.getters.userIsAdmin" class="text-center alert alert-info mt-4 col-8 offset-2" role="alert">
      {{ page.doubleEntryMessage }}
    </div>

    <div v-if="this.saleError.isError" class="text-center alert alert-danger mt-4 col-8 offset-2" role="alert">
      {{ this.saleError.errorMessage }}
    </div>

    {{ /* BUTTON HELP (MOBILE) */ }}
    <div class="button-help-mobile text-right mb-3">
      <b-icon class="mr-2" icon="question-square" font-scale="1.5" variant="danger" animation="throb" v-on:click="clickButtonHelpMobile"></b-icon>
    </div>

    {{ /* DIV HELP (MOBILE) */ }}
    <div
        class="div-help-mobile"
        :class="(showHelpMobile) ? 'collapsed' : ''"
    >
      <div class="card app-box-shadow p-3" v-if="!this.$store.getters.userIsAdmin">
        <div class="text-right">
          <b-icon
              v-on:click="closeBlockHelpMobile"
              class=""
              icon="x-square"
              font-scale="1.5"
              variant="info"
          ></b-icon>
        </div>

        <hr v-if="!SaleModule.shop.isMonth"/>

        <div class="" v-if="askForHelp.isEmailSend">
          <div class="alert alert-success" role="alert">
            <h6 class="alert-heading">Demande d'aide envoyée avec succès</h6>
            <hr />
            <p class="mb-0">
              <button v-on:click="resetEmailSend" class="btn btn-outline-info ml-2">
                Effectuer une autre demande
              </button>
            </p>
          </div>
        </div>
        <div v-else class="mt-1">
          <button  v-on:click="askForHelp.clicked = true" class="btn btn-outline-danger">
            <b-icon class="mr-2" icon="question-square" font-scale="1.5" variant="danger" animation="throb"></b-icon>
            Demander de l'aide
          </button>
        </div>

        <hr v-if="!SaleModule.shop.isMonth"/>

        <div class="total-month" v-if="!SaleModule.shop.isMonth">
          <div class="card">
            <div class="card-body">
              <h6 class="text-center">Total du mois en cours</h6>

              <div class="d-flex justify-content-between">
                <span class="big-text ml-4">HT</span>
                <span class="big-text">€ {{ this.$store.getters.getCountSalesWithoutVat }}</span>
              </div>

              <div class="d-flex justify-content-between">
                <span class="big-text ml-4">T.T.C</span>
                <span class="big-text">€ {{ this.$store.getters.getCountSalesWithVat }}</span>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="legend mt-4">
          <div>
            <b-icon icon="pencil-square" font-scale="1.5" variant="info"></b-icon> = Saisie en cours
          </div>
          <div class="mt-2">
            <b-icon icon="check-circle" font-scale="1.5" variant="primary"></b-icon> = CA à jour
          </div>
          <div class="mt-2">
            <b-icon icon="person" font-scale="1.5" variant="warning"></b-icon> = Rempli par le Centre
          </div>
          <div class="mt-2">
            <b-icon icon="exclamation-triangle" font-scale="1.5" variant="danger"></b-icon> = CA en retard
          </div>
        </div>

        {{ /* BUTTON SUBMIT */ }}
        <div v-if="!this.$store.getters.userIsAdmin" class="d-flex justify-content-end mb-3 mt-4 mr-3">
          <button class="btn btn-success submit-all-sale-desktop" v-on:click="submitForm(SaleModule.shop.isMonth)">
            Valider la saisie chiffre d'affaires
          </button>
        </div>
      </div>
    </div>

    <div class="d-md-flex mt-md-5">
      <div class="p-0" :class="(this.$store.getters.userIsAdmin) ? 'col-12' : 'col-12 col-md-12 col-xl-8 offset-xl-2'">
        {{ /* CARD SALES */ }}
        <div class="card border-0 app-box-shadow">
          <div class="p-md-3">
            {{ /* HEADER CARD */}}
            <div class="d-md-flex justify-content-between">
              <div class="w-100">
                <h3 class="text-center text-md-left pt-3 pt-md-0" v-if="!this.$store.getters.userIsAdmin">
                  <b-icon class="mr-3" icon="bar-chart-line" font-scale="1.5" variant="primary"></b-icon>
                  {{ (this.$store.getters.userIsAdmin) ? 'Chiffres d\'affaires : ' + SaleModule.shop.name : 'Vos chiffres d\'affaires' }}
                </h3>
                {{ /* DATE LIMIT SALES */ }}
                <div class="small">
                  <div class="text-center text-md-left" v-if="!this.$store.getters.userIsAdmin && !SaleModule.shop.isMonth">
                    Vous avez jusqu'au {{ getDateLimitPeriod(SaleModule.shop.dateLimitSale, 'DD MMMM YYYY') }} pour remplir votre chiffre d'affaires
                  </div>
                </div>
              </div>

              {{ /* BUTTON CHANGE PERIOD */ }}
              <div>
                <div class="text-right mr-2">Choisir une période</div>
                <Datepicker
                    @selected="clickDatePicker"
                    class="mt-1 mr-2"
                    id="date-sale-month"
                    :format="formatDatepicker"
                    :minimumView="'month'"
                    :maximumView="'month'"
                    :value="datepicker.defaultDate"
                    input-class="border-0 text-right"
                    :disabledDates="datepicker.disabledDates"
                ></Datepicker>
              </div>
            </div>

            <hr />

            {{ /* IF SHOP IS MONTH */ }}
            <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-0" v-if="loader.isLoading">
              <div class="text-center">
                <b-spinner variant="success" label="Spinning"></b-spinner>
                <div class="text-primary">Chargement en cours ...</div>
              </div>
            </div>
            <div v-else>
              <div v-if="SaleModule.shop.isMonth">
                <div class="d-flex justify-content-center">
                  <div class="d-flex flex-column justify-content-center">
                    <b-icon
                        v-if="!this.$store.getters.userIsAdmin"
                        v-on:click="period.monthSubtract += 1
                        changePeriodSales(handleDate(period.monthSubtract, 'MM'), period.monthSubtract, handleDate(period.monthSubtract, 'YYYY'))"
                        class="button-period-sales button-period-sales-month mr-2 mr-md-3 p-2"
                        icon="chevron-left"
                        font-scale="3"
                        variant="secondary"
                    ></b-icon>
                  </div>

                  {{ /* CARD */ }}
                  <div
                      class="card card-sales-month d-flex flex-column justify-content-between app-box-shadow-button border-0 col-8 col-md-4 mb-3 p-2"
                      :class="[
                        (saleModel.userWithVat !== null || saleModel.userWithoutVat !== null) ? 'card-fill' : '',
                        (saleModel.bgColor !== null) ? saleModel.bgColor : ''
                    ]"
                  >

                    <div class="text-white text-center mt-2 mb-2"><i>Quel a été votre chiffre d'affaires ?</i></div>
                    {{ /* ICON */ }}
                    <div class="icon d-flex justify-content-center">
                      <div v-if="saleModel.bgColor === 'bg-danger'">
                        <b-icon icon="exclamation-triangle" font-scale="1.5" variant="white"></b-icon>
                      </div>
                      <div v-else-if="saleModel.bgColor === 'bg-primary'">
                        <b-icon icon="check-circle" font-scale="1.5" variant="white"></b-icon>
                      </div>
                      <div v-else-if="saleModel.bgColor === 'bg-warning'">
                        <b-icon icon="person" font-scale="1.5" variant="white"></b-icon>
                      </div>
                    </div>

                    {{ /* DATE */ }}
                    <h3 class="text-white text-center">{{ getDatePeriod('MMMM YYYY') }}</h3>

                    <form @submit.prevent="submitForm(true)">
                      <div class="d-flex justify-content-center">
                        <div class="input-group w-75" v-if="checkUserCanEdit(saleModel)">
                          <input
                              type="number"
                              step="0.01"
                              class="form-control border-0"
                              v-model="saleModel.totalWithoutVat"
                              placeholder="Chiffre d'affaires"
                              v-on:keyup="onChangeInputWithoutVat($event, saleModel)"
                          >
                          <div class="input-group-append">
                            <div class="input-group-text text-grey bg-white border-0">HT</div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between" v-else>
                          <span class="text-light big-text">{{ saleModel.totalWithoutVat }} €</span>
                          <span class="text-light big-text ml-4">HT</span>
                        </div>
                      </div>

                      <div class="d-flex justify-content-center mt-4">
                        <div class="input-group w-75"  v-if="checkUserCanEdit(saleModel)">
                          <input
                              type="number"
                              step="0.01"
                              class="form-control border-0"
                              v-model="saleModel.totalWithVat"
                              placeholder="Chiffre d'affaires"
                              v-on:keyup="onChangeInputWithVat($event, saleModel)"
                          >
                          <div class="input-group-append">
                            <div class="input-group-text text-grey bg-white border-0">TTC</div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between" v-else>
                          <span class="text-light big-text">{{ saleModel.totalWithVat }} €</span>
                          <span class="text-light big-text ml-4">TTC</span>
                        </div>

                        <input type="hidden" v-model="saleModel.id">
                      </div>

                      <div class="text-center mt-1" v-if="errorFormSales">
                        <p class="form-error" :class="(saleModel.bgColor === 'bg-danger') ? 'text-warning' : 'text-danger'">
                          {{ errorFormSales }}
                        </p>
                      </div>

                      <div class="d-flex justify-content-center mt-5" v-if="$store.getters.userIsAdmin || saleModel.bgColor !== 'bg-primary'">
                        <input
                            type="submit"
                            class="btn ml-3 mt-2"
                            v-if="checkUserCanEdit(saleModel)"
                            :class="(saleModel.bgColor !== '') ? 'btn-outline-light' : 'btn-primary'"
                            value="Valider"
                        />
                      </div>
                    </form>

                    <div class="text-white small-text text-center" v-if="saleModel.bgColor === 'bg-warning'">
                      Chiffre d'affaires rempli par un administrateur
                    </div>
                    <div class="text-white small-text text-center" v-else-if="saleModel.bgColor === 'bg-danger'">
                      Retard, merci de contacter un administrateur
                    </div>
                    <div class="text-white small-text text-center" v-else>
                      Vous avez jusqu'au {{ getDateLimitPeriod(SaleModule.shop.dateLimitSale, 'DD MMMM YYYY') }} pour remplir votre chiffre d'affaires
                    </div>
                  </div>

                  <div class="d-flex flex-column justify-content-center">
                    <b-icon
                        v-if="!this.$store.getters.userIsAdmin"
                        v-on:click="period.monthSubtract -= 1
                      changePeriodSales(handleDate(period.monthSubtract, 'MM'), period.monthSubtract, handleDate(period.monthSubtract, 'YYYY'))"
                        class="button-period-sales button-period-sales-month ml-2 ml-md-3 p-2"
                        :class="(period.monthSubtract === 0) ? 'button-period-disabled' : ''"
                        icon="chevron-right"
                        font-scale="3"
                        variant="secondary"
                    ></b-icon>
                  </div>
                </div>
              </div>
              <div v-else>
                <form ref="formSales"  @submit.prevent="submitForm(false)" class="form-sales w-100">
                  <div class="d-flex flex-wrap mt-4 pb-4 w-100">
                    {{ /* CARD SALES BY DAY */ }}
                    <div
                        class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3"
                        v-for="(obj, index) in listByDay" :key="index"
                    >
                      <div
                          class="card card-sales app-box-shadow-button border-0 p-2 mt-3"
                          v-on:click="cardActiveEvent(index, obj)"
                          :class="[
                          (card.index === index) ? 'card-active' : '',
                          (obj.userWithVat !== null || obj.userWithoutVat !== null) ? 'card-fill' : '',
                          (obj.bgColor !== null) ? obj.bgColor : ''
                      ]"
                      >
                        {{ /* DATE AND ICONS */ }}
                        <div class="d-flex justify-content-between">
                          <div class="date" :class="(obj.bgColor === 'bg-danger') ? 'text-white' : 'text-grey'">
                            <div class="sale-date-desktop" >
                              {{ obj.date | moment((card.index === index || (obj.userWithVat !== null || obj.userWithoutVat !== null)) ? 'ddd DD' : 'DD')  }}
                            </div>
                            <div class="sale-date-mobile">
                              {{ obj.date | moment('DD')  }}
                            </div>
                          </div>

                          <div class="icon-desktop icon" >
                            <div v-if="obj.bgColor === 'bg-info'">
                              <b-icon icon="pencil-square" font-scale="1.5" variant="white"></b-icon>
                            </div>
                            <div v-else-if="obj.bgColor === 'bg-danger'">
                              <b-icon icon="exclamation-triangle" font-scale="1.5" variant="white"></b-icon>
                            </div>
                            <div v-else-if="obj.bgColor === 'bg-primary'">
                              <b-icon icon="check-circle" font-scale="1.5" variant="white"></b-icon>
                            </div>
                            <div v-else-if="obj.bgColor === 'bg-warning'">
                              <b-icon icon="person" font-scale="1.5" variant="white"></b-icon>
                            </div>
                          </div>
                        </div>

                        {{ /* INPUTS */ }}
                        <div class="inputs mt-3">
                          <div class="input-group" v-if="checkUserCanEdit(obj)">
                            <input
                                type="number"
                                step="0.01"
                                class="form-control border-0"
                                v-model="obj.totalWithoutVat"
                                placeholder="CA"
                                v-on:keyup="onChangeInputWithoutVat($event, listByDay[index])"
                                v-on:blur="incrementCountFillCardSales(obj)"
                            >
                            <input type="hidden" v-model="obj.userWithoutVat">
                            <div class="input-group-append">
                              <div class="input-group-text text-grey bg-white border-0">HT</div>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between" v-else>
                            <span class="text-light big-text">{{ (obj.totalWithoutVat) ? obj.totalWithoutVat : 0 }} €</span>
                            <span class="text-light big-text">HT</span>
                          </div>

                          <div class="input-group mt-2" v-if="checkUserCanEdit(obj)">
                            <input
                                type="number"
                                step="0.01"
                                class="form-control border-0"
                                v-model="obj.totalWithVat"
                                placeholder="CA"
                                v-on:keyup="onChangeInputWithVat($event, listByDay[index])"
                                v-on:blur="incrementCountFillCardSales(obj)"
                            >
                            <input type="hidden" v-model="obj.userWithVat">
                            <div class="input-group-append">
                              <div class="input-group-text text-grey bg-white border-0">TTC</div>
                            </div>
                          </div>
                          <div v-else>
                            <div class="d-flex justify-content-between">
                              <span class="text-light big-text">{{ (obj.totalWithVat) ? obj.totalWithVat : 0 }} €</span>
                              <span class="text-light big-text">T.T.C</span>
                            </div>

                            <p class="text-light small-text mt-3 text-center" v-if="obj.bgColor === 'bg-danger'">Retard, merci de contacter un administrateur</p>
                          </div>
                        </div>

                        <div class="total-mobile mt-4 text-white">
                          <div class="d-flex justify-content-center">
                            {{ (obj.totalWithoutVat) ? obj.totalWithoutVat + ' €' : '' }}
                            <span class="ml-2">{{ (obj.totalWithoutVat) ? ' (HT)' : '' }}</span>
                          </div>
                          <div class="d-flex justify-content-center">
                            {{ (obj.totalWithVat) ? obj.totalWithVat + ' €' : '' }}
                            <span class="ml-2">{{ (obj.totalWithVat) ? ' (TTC)' : '' }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {{ /* BUTTON SUBMIT */ }}
                  <div v-if="this.$store.getters.userIsAdmin" class="d-flex justify-content-end mb-3 mt-4 mr-3">
                    <input
                        type="submit"
                        class="btn btn-success submit-all-sale-desktop"
                        value="Valider la saisie chiffre d'affaires"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2">
        {{ /* BUTTON HELP */ }}
        <div class="card app-box-shadow p-2 block-help-desktop position-fixed" v-if="!this.$store.getters.userIsAdmin">
          <div v-if="askForHelp.isEmailSend">
            <div class="alert alert-success" role="alert">
              <h6 class="alert-heading">Demande d'aide envoyée avec succès</h6>
              <hr />
              <p class="mb-0">
                <button v-on:click="resetEmailSend" class="btn btn-outline-info ml-2">
                  Effectuer une autre demande
                </button>
              </p>
            </div>
          </div>
          <div v-else class="mt-1">
            <button  v-on:click="askForHelp.clicked = true" class="btn btn-block btn-outline-danger text-left">
              <b-icon class="mr-4" icon="question-square" font-scale="1.5" variant="danger" animation="throb"></b-icon>
              <span>Demander de l'aide</span>
            </button>
          </div>

          <hr v-if="!SaleModule.shop.isMonth"/>

          <div class="total-month" v-if="!SaleModule.shop.isMonth">
            <div class="card">
              <div class="card-body">
                <h6 class="text-center">Total du mois en cours :</h6>

                <div class="d-flex justify-content-between">
                  <span class="big-text">{{ this.$store.getters.getCountSalesWithoutVat }} €</span>
                  <span class="big-text ml-4">HT</span>
                </div>

                <div class="d-flex justify-content-between">
                  <span class="big-text">{{ this.$store.getters.getCountSalesWithVat }} €</span>
                  <span class="big-text ml-4">T.T.C</span>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div class="legend">
            <div>
              <b-icon icon="pencil-square" font-scale="1.5" variant="info"></b-icon> = Saisie en cours
            </div>
            <div class="mt-2">
              <b-icon icon="check-circle" font-scale="1.5" variant="primary"></b-icon> = CA à jour
            </div>
            <div class="mt-2">
              <b-icon icon="person" font-scale="1.5" variant="warning"></b-icon> = Rempli par le Centre
            </div>
            <div class="mt-2">
              <b-icon icon="exclamation-triangle" font-scale="1.5" variant="danger"></b-icon> = CA en retard
            </div>
          </div>

          <hr />

          {{ /* BUTTON SUBMIT */ }}
          <div v-if="!this.$store.getters.userIsAdmin" class="mb-2">
            <button
                v-if="!SaleModule.shop.isMonth && (getLastDayInMonthPeriod !== this.$store.getters.getCountSales)"
                class="btn btn-success submit-all-sale-desktop"
                v-on:click="submitForm(SaleModule.shop.isMonth)"
            >
              Valider la saisie chiffre d'affaires
            </button>
            <div class="text-center" v-else-if="!SaleModule.shop.isMonth">
              <b-alert show variant="info">
                <div class="d-flex justify-content-between">
                  <span>Votre mois est validé</span>
                  <b-icon icon="check-circle" font-scale="1.5" variant="secondary"></b-icon>
                </div>
              </b-alert>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!this.$store.getters.userIsAdmin" class="footer-sales mt-5">
      <div class="d-flex justify-content-center">
        <div class="text-grey small-text">
          Socri REIM | {{ ChannelModule.channel.name }}
        </div>
      </div>
    </div>

    {{ /* INPUTS MOBILE */ }}
    <div
        v-if="card.index !== null"
        class="card border-0 app-box-shadow p-2 sale-mobile position-fixed"
        :class="(card.bgColor !== null) ? card.bgColor : 'bg-green-dark'"
    >
      <div class="header-sale-mobile mb-4">
        <div class="d-flex justify-content-end">
          <b-icon
              v-on:click="closeBlockSaleMobile"
              class=""
              icon="x-square"
              font-scale="1.5"
              variant="danger"
          ></b-icon>
        </div>

        <div class="text-center">
          <div class="d-flex justify-content-center">
            <div>
              <b-icon
                  v-on:click="
                    (card.index > 0) ? card.index -= 1 : card.index;
                    navBlockSaleMobile(card.index)
                  "
                  class="button-period mr-3 p-2"
                  icon="chevron-left"
                  font-scale="2.5"
                  :variant="(card.index > 0) ? 'light' : 'secondary'"
              ></b-icon>
            </div>
            <div class="text-center mt-2 mr-4 ml-4 text-capitalize">
              {{ card.date | moment('dddd DD MMMM YYYY') }}
            </div>
            <div>
              <b-icon
                  v-on:click="
                    (card.index < listByDay.length - 1) ? card.index += 1 : card.index;
                    navBlockSaleMobile(card.index)
                  "
                  class="button-period mr-3 p-2"
                  icon="chevron-right"
                  font-scale="2.5"
                  :variant="(card.index < listByDay.length - 1) ? 'light' : 'secondary'"
              ></b-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="inputs mt-3">
        <div class="input-group" v-if="checkUserCanEdit(card)">
          <input
              type="number"
              step="0.01"
              class="form-control border-0"
              v-model="card.totalWithoutVat"
              placeholder="CA"
              v-on:keyup="onChangeInputWithoutVat($event, card)"
              v-on:blur="incrementCountFillCardSales(card)"
          >
          <input type="hidden" v-model="card.userWithoutVat">
          <div class="input-group-append">
            <div class="input-group-text text-grey bg-white border-0">HT</div>
          </div>
        </div>
        <div class="d-flex justify-content-center" v-else>
          <span class="text-light big-text">{{ (card.totalWithoutVat) ? card.totalWithoutVat : 0 }} €</span>
          <span class="text-light big-text ml-4">HT</span>
        </div>

        <div class="input-group mt-2" v-if="checkUserCanEdit(card)">
          <input
              type="number"
              step="0.01"
              class="form-control border-0"
              v-model="card.totalWithVat"
              placeholder="CA"
              v-on:keyup="onChangeInputWithVat($event, card)"
              v-on:blur="incrementCountFillCardSales(card)"
          >
          <input type="hidden" v-model="card.userWithVat">
          <div class="input-group-append">
            <div class="input-group-text text-grey bg-white border-0">TTC</div>
          </div>
        </div>
        <div v-else>
          <div class="d-flex justify-content-center">
            <span class="text-light big-text">{{ (card.totalWithVat) ? card.totalWithVat : 0 }} €</span>
            <span class="text-light big-text ml-4">TTC</span>
          </div>

          <p class="text-light small-text mt-3 text-center" v-if="card.bgColor === 'bg-danger'">Retard, merci de contacter un administrateur</p>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-3 mb-3">
        <button
            class="btn btn-outline-light"
            v-if="checkUserCanEdit(card)"
            v-on:click="submitSaleMobile"
        >
          Valider
        </button>
      </div>
    </div>

    {{/* Modal Ask For Help */}}
    <div class="div-modal">
      <b-modal
          :key="componentKey + 100"
          id="modal-form-ask-for-help"
          ref="modal"
          title="Vous avez demandé de l'aide ?"
          v-model="askForHelp.clicked"
          @hidden="resetModal"
          @ok="handleModalAskForHelp"
          header-bg-variant="danger"
          header-text-variant="light"
          body-bg-variant="light"
          footer-bg-variant="light"
      >
        <b-alert show variant="info">
          Merci de renseigner votre téléphone OU votre e-mail,
          <br />
          Nos administrateurs vous contacteront dans les plus brefs délais
        </b-alert>

        <form ref="form">
          <div class="form-group">
            <label for="ask-for-help-phone">Votre téléphone</label>
            <input class="form-control" id="ask-for-help-phone" v-model="askForHelp.form.phone" type="tel" placeholder="Votre téléphone">
          </div>
          <div class="form-group">
            <label for="ask-for-help-email">Votre Email</label>
            <input class="form-control" id="ask-for-help-email" v-model="askForHelp.form.email" type="email" placeholder="Votre email">
          </div>
        </form>

        <div v-if="askForHelp.form.error">
          <span class="text-danger">{{ askForHelp.form.error }}</span>
        </div>

        <template #modal-footer="{ ok, cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="secondary" @click="cancel()">
            Annuler
          </b-button>
          <b-button size="sm" variant="success" @click="ok()">
            Envoyez la demande d'aide
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>

</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      componentKey: 0,
      showHelpMobile: false,
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      userCanEdit: this.userCanEditComputed,
      errorFormSales : false,
      saleError: {
        isError: false,
        message: null
      },
      card: {
        id: null,
        totalWithVat: null,
        totalWithoutVat: null,
        userWithVat: null,
        userWithoutVat: null,
        vatRate: null,
        date: null,
        index: null,
        bgColor: null
      },
      datepicker: {
        defaultDate: null,
        disabledDates: {
          to: null, // Disable all dates up to specific date
          from: new Date(new Date()), // Disable all dates after specific date
        },
      },
      page: {
        doubleEntryMessage:
            'Attention votre magasin est en double saisie, merci de bien remplir les deux champs du formulaire pour chaque ligne.'
        ,
      },
      period : {
        month : this.handleDate(0, 'MM'),
        monthSubtract: 0,
        dateLimit :null,
        year : this.handleDate(0, 'YYYY')
      },
      listByDay: [],
      countFillCardSales: this.incrementFillCardSalesComputed,
      saleModel: {
        id: null,
        date: null,
        totalWithVat: null,
        totalWithoutVat: null,
        userWithVat: null,
        userWithoutVat: null,
        vatRate: null,
        bgColor: null
      },
      askForHelp: {
        isEmailSend: false,
        clicked: false,
        form: {
          phone: null,
          email: null,
          error: null
        }
      },
    }
  },
  created() {
    // Redirect if is not user sales
    if(this.$store.getters.userIsShop) {
      if(Number(this.$route.params.id) !== Number(this.$store.getters.getCurrentUser.shop.id)) {
        this.$router.replace({ name: 'sale', params: {id: this.$store.getters.getCurrentUser.shop.id} }, () => {})
      } else {
        this.prepareDataPage()
      }
    } else {
      this.prepareDataPage()
    }

    this.userCanEdit = this.userCanEditComputed
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------------  BLOCK SALE MOBILE ----------------*/
    clickButtonHelpMobile() {
      this.showHelpMobile = !this.showHelpMobile
    },
    closeBlockHelpMobile() {
      this.showHelpMobile = false
    },
    /**
     * Toggle active on card
     *
     * @param index
     * @param sale
     */
    cardActiveEvent(index, sale) {
      // Set obj card
      this.card.id = (sale.id) ? sale.id : null
      this.card.index = index
      this.card.date = sale.date
      this.card.vatRate  = (sale.vatRate) ? sale.vatRate : null
      this.card.totalWithoutVat = (sale.totalWithoutVat) ? sale.totalWithoutVat : null
      this.card.totalWithVat = (sale.totalWithVat) ? sale.totalWithVat : null
      this.card.userWithoutVat = (sale.userWithoutVat) ? sale.userWithoutVat : null
      this.card.userWithVat = (sale.userWithVat) ? sale.userWithVat : null
      this.card.bgColor = (sale.bgColor) ? sale.bgColor : 'bg-green-dark'
    },
    /**
     * Nav between card
     */
    navBlockSaleMobile(index) {
      this.card.index = index
      this.card.date = this.listByDay[index].date
      this.card.vatRate  = (this.listByDay[index].vatRate) ? this.listByDay[index].vatRate : null
      this.card.totalWithoutVat = (this.listByDay[index].totalWithoutVat) ? this.listByDay[index].totalWithoutVat : null
      this.card.totalWithVat = (this.listByDay[index].totalWithVat) ? this.listByDay[index].totalWithVat : null
      this.card.userWithoutVat = (this.listByDay[index].userWithoutVat) ? this.listByDay[index].userWithoutVat : null
      this.card.userWithVat = (this.listByDay[index].userWithVat) ? this.listByDay[index].userWithVat : null
      this.card.bgColor = (this.listByDay[index].bgColor) ? this.listByDay[index].bgColor : 'bg-green-dark'
    },
    /**
     * Close block sale mobile
     */
    closeBlockSaleMobile() {
      this.card = {
        totalWithVat: null,
        totalWithoutVat: null,
        userWithVat: null,
        userWithoutVat: null,
        date: null,
        index: null,
        bgColor: null
      }
    },
    submitSaleMobile() {
      if(this.$store.getters.userIsShop) {
        if (this.getLastDayInMonthPeriod !== this.countFillCardSales) {
          this.$swal({
            title: 'Validation de votre saisie chiffre d\'affaires',
            text: "Valider votre / vos saisie(s)  ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2FA76E',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
          }).then((result) => {
            if (result.value) {
              this.initSubmitMobile()
            }
          })
        } else {
          this.$swal({
            title: 'Validation du mois en cours',
            text: "Attention: Vous ne pourrez plus éditer vos saisies, Si vous validez, un mail sera envoyé au centre",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2FA76E',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
          }).then((result) => {
            if (result.value) {
              this.initSubmitMobile()
            }
          })
        }
      }
    },
    initSubmitMobile() {
      // Send create
      this.$store.dispatch('createOrUpdateSales', {
        centerId: this.$store.getters.getChannel.center.id,
        data: [this.card],
        shopId: this.SaleModule.shop.id,
        userId: this.AuthModule.currentUser.id
      })
          .then(() => {
            // Fetch Sales
            this.$store.dispatch('fetchSales', {
              centerId: this.$store.getters.getChannel.center.id,
              month : this.period.month,
              year : this.period.year,
              shopId : this.$route.params.id
            })
                .then(() => {
                  // ListByDay
                  this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

                  // Hide loader
                  this.hideLoader()
                })
          })
    },
    /*----------------- GET DATA FROM API ------------------*/
    prepareDataPage()
    {
      // set default date for datepicker
      let dateObj = new Date()
      this.datepicker.defaultDate = moment(dateObj).format('YYYY-MM-DD')

      // Display loader
      this.showLoader()

      // Key component
      this.componentKey += 1

      // Set period
      this.period.month = this.handleDate(0, 'MM')
      this.period.year = this.handleDate(0, 'YYYY')
      this.period.dateLimit = this.getDateLimitPeriod(this.SaleModule.shop.dateLimitSale, 'YYYY-MM-DD')

      // Fetch Shop
      let model = {
        centerId: this.$store.getters.getChannel.center.id,
        shopId: this.$route.params.id
      }

      this.$store.dispatch('fetchSalesShop', model)

      // Fetch Sales
      this.$store.dispatch('fetchSales', {
        centerId: this.$store.getters.getChannel.center.id,
        month : this.period.month,
        year : this.period.year,
        shopId : this.$route.params.id
      })
          .then(() => {
            // ListByDay
            this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

            // Count list sales
            this.countFillCardSales = this.$store.getters.getCountSales

            // Sale model
            this.saleModel = this.setModelSaleComputed

            // Hide loader
            this.hideLoader()
          })
    },
    /*----------------- DATE ------------------*/
    /**
     * Format date for datepicker
     */
    formatDatepicker(date) {
      let dateObj = new Date(date)
      return moment(dateObj).format('MMMM yyyy');
    },
    /**
     * Get date period
     *
     * @param format
     */
    getDatePeriod(format) {
      let dateObj = new Date(this.period.year + '-' + this.period.month + '-01')
      return moment(dateObj).format(format)
    },
    handleDate($monthToSubtract, format)
    {
      let dateObj = new Date()
      let currentDate = moment(dateObj).format('YYYY-MM-DD')
      let newDate = moment(currentDate).subtract($monthToSubtract, 'month')

      return newDate.format(format)
    },
    getDateLimitPeriod(dateLimitSale, format)
    {
      let constructDateLimitDay = (dateLimitSale < 10) ? '0' + dateLimitSale : dateLimitSale
      let constructDate = this.period.year + '-' + this.period.month + '-' + constructDateLimitDay

      let dateObj = new Date(String(constructDate))
      let date = moment(dateObj)
      let newDate = moment(date).add(1, 'month')

      return newDate.format(format)
    },
    /**
     * List days of current month
     *
     * @returns {[]}
     */
    getDaysInMonth(month, year) {
      // current date for late sale
      let dateObj = new Date()
      let currentDate = moment(dateObj).format('YYYY-MM-DD')

      // init var
      let dayArray = []
      let strDate = new Date(year + '-' + month + '-01')

      // Set start and end date
      let startDayMonth = moment(strDate).format('YYYY-MM-DD')
      let endDayMonth = moment(strDate).format('YYYY-MM-') + moment(strDate).daysInMonth()

      // For loop between start and end day
      while (startDayMonth <= endDayMonth) {
        // Find sale by date
        let findSales = this.SaleModule.sales.find(item => item.date === startDayMonth)

        let model = {
          date : startDayMonth,
          id : (findSales !== undefined) ? findSales.id : null,
          totalWithVat : (findSales !== undefined) ? findSales.totalWithVat : null,
          totalWithoutVat : (findSales !== undefined) ? findSales.totalWithoutVat : null,
          userWithVat : (findSales !== undefined) ? (findSales.userWithVat) ? findSales.userWithVat.id : null : null,
          userWithoutVat : (findSales !== undefined) ? (findSales.userWithoutVat) ? findSales.userWithoutVat.id : null : null,
          vatRate : (findSales !== undefined) ? findSales.vatRate : null,
          bgColor : (findSales !== undefined) ? this.handleColorInput(findSales, true) : (currentDate > this.period.dateLimit) ? 'bg-danger' : '',
        }

        dayArray.push(model)
        startDayMonth = moment(startDayMonth).add(1, 'days').format('YYYY-MM-DD')
      }

      return dayArray
    },
    /**
     * Handle color input
     *
     * @param sale
     * @param isVat
     */
    handleColorInput(sale, isVat)
    {
      // current date for late sale
      let currentDate = moment().format('YYYY-MM-DD')

      if(sale !== undefined) {
        if(isVat) {
          if(sale.userWithVat != null) {
            // IsMonth or daily
            if(sale.userWithVat instanceof Object) {
              if(sale.userWithVat.id === this.AuthModule.currentUser.id) {
                return 'bg-primary'
              } else {
                return 'bg-warning'
              }
            } else {
              if(sale.userWithVat === this.AuthModule.currentUser.id) {
                return 'bg-primary'
              } else {
                return 'bg-warning'
              }
            }
          } else {
            if(currentDate > this.period.dateLimit) {
              return 'bg-danger'
            } else {
              return ''
            }
          }
        } else {
          if(sale.userWithoutVat != null) {
            if(sale.userWithoutVat instanceof Object) {
              if(sale.userWithoutVat.id === this.AuthModule.currentUser.id) {
                return 'bg-primary'
              } else {
                return 'bg-warning'
              }
            } else {
              if(sale.userWithoutVat === this.AuthModule.currentUser.id) {
                return 'bg-primary'
              } else {
                return 'bg-warning'
              }
            }
          } else {
            if(currentDate > this.period.dateLimit) {
              return 'bg-danger'
            } else {
              return ''
            }
          }
        }
      }
    },
    /*----------------- Change period ------------------*/
    changePeriodSales($month, $monthSubtract, $year)
    {
      // Display loader
      this.showLoader()

      // Close active card
      this.card.index = null

      // Change period
      this.period.month = ($month < 10) ? '0' + $month : $month
      this.period.monthSubtract = $monthSubtract
      this.period.year = $year
      this.period.dateLimit = this.getDateLimitPeriod(this.SaleModule.shop.dateLimitSale, 'YYYY-MM-DD')

      // Fetch Sales
      this.$store.dispatch('fetchSales', {
        centerId: this.$store.getters.getChannel.center.id,
        month : this.period.month,
        year : this.period.year,
        shopId : this.$route.params.id
      })
        .then(() => {
          // ListByDay
          this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

          // Sale model
          this.saleModel = this.setModelSaleComputed
          // Count list sales
          this.countFillCardSales = this.$store.getters.getCountSales

          // Hide loader
          this.hideLoader()
        })
    },
    /*----------------- On change input ------------------*/
    onChangeInputWithVat($event, sale)
    {
      if($event.target.value) {
        if(!this.SaleModule.shop.doubleSaleEntry) {
          // Refresh totalWithoutVat
          sale.totalWithoutVat = null
          //AutoComplete field totalWithoutVat
          sale.totalWithoutVat = this.calculationSale('withoutVat', sale.totalWithVat)
        }

        // Set user without vat
        sale.userWithoutVat = this.AuthModule.currentUser.id
        // Color input
        sale.bgColor = 'bg-info'
        // Set user
        sale.userWithVat = this.AuthModule.currentUser.id
      }
    },
    onChangeInputWithoutVat($event, sale)
    {
      if($event.target.value) {
        if(!this.SaleModule.shop.doubleSaleEntry) {
          // Refresh totalWithVat
          sale.totalWithVat = null
          // AutoComplete field totalWithVat
          sale.totalWithVat = this.calculationSale('withVat', sale.totalWithoutVat)
        }

        // Set user without vat
        sale.userWithVat = this.AuthModule.currentUser.id
        // Color input
        sale.bgColor = 'bg-info'
        // Set user
        sale.userWithoutVat = this.AuthModule.currentUser.id
      }
    },
    incrementCountFillCardSales(sale) {
      if(sale.totalWithVat && sale.totalWithoutVat) {
        this.countFillCardSales ++
      }
    },
    /*----------------- CalculationVatRate ------------------*/
    /**
     * Calculation vatRate
     */
    calculationSale(saleType, total)
    {
      let vatPercentage = (this.SaleModule.shop.vatRate / 100) + 1

      if(saleType === 'withVat') {
        let calculationSaleWithVat = Number(total) * Number(vatPercentage)
        return Math.round((calculationSaleWithVat + Number.EPSILON) * 100) / 100
      } else {
        let calculationSaleWithoutVat = Number(total) / Number(vatPercentage)
        return Math.round((calculationSaleWithoutVat + Number.EPSILON) * 100) / 100
      }
    },
    /*----------------- DATEPICKER -------------*/
    /**
     * Event click datePicker date
     *
     * @param value
     */
    clickDatePicker(value)
    {
      // Pass str to date Obj
      var dateObj = new Date(value)
      var momentObj = moment(dateObj)

      this.changePeriodSales(Number(momentObj.format('MM')), 0, Number(momentObj.format('YYYY')))
    },
    /*----------------- Submit form ------------------*/
    submitForm(isMonth)
    {
      if(isMonth) {
        if(this.$store.getters.userIsShop) {
          this.$swal({
            title: 'Validation du mois en cours',
            text: "Attention: Vous ne pourrez plus éditer vos saisies, Si vous validez, un mail sera envoyé au centre",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2FA76E',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
          }).then((result) => {
            if (result.value) {
              this.initSubmit(isMonth)
            }
          })
        } else {
          this.initSubmit(isMonth)
        }
      } else {
        if(this.$store.getters.userIsShop) {
          if(this.getLastDayInMonthPeriod !== this.countFillCardSales) {
            this.$swal({
              title: 'Validation de votre saisie chiffre d\'affaires',
              text: "Valider votre / vos saisie(s)  ?",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#2FA76E',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non'
            }).then((result) => {
              if(result.value) {
                this.initSubmit(isMonth)
              }
            })
          } else {
            this.$swal({
              title: 'Validation du mois en cours',
              text: "Attention: Vous ne pourrez plus éditer vos saisies, Si vous validez, un mail sera envoyé au centre",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#2FA76E',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non'
            }).then((result) => {
              if(result.value) {
                this.initSubmit(isMonth)
              }
            })
          }
        } else if (this.$store.getters.userIsAdmin) {
          this.initSubmit(isMonth)
        }
      }
    },
    initSubmit(isMonth)
    {
      // Display loader
      this.showLoader()

      if(isMonth) {
        if(this.SaleModule.shop.doubleSaleEntry && (!this.saleModel.totalWithoutVat || !this.saleModel.totalWithVat)) {
          this.loader.isLoading = false
          this.errorFormSales = 'Merci de remplir les deux champs'
        } else {
          this.errorFormSales = false

          // Send create
          this.$store.dispatch('createOrUpdateSales', {
            centerId: this.$store.getters.getChannel.center.id,
            data: [this.saleModel],
            shopId: this.SaleModule.shop.id,
            userId: this.AuthModule.currentUser.id
          })
              .then(() => {
                // Fetch Sales
                this.$store.dispatch('fetchSales', {
                  centerId: this.$store.getters.getChannel.center.id,
                  month : this.period.month,
                  year : this.period.year,
                  shopId : this.$route.params.id
                })
                    .then(() => {
                      // Set sale model
                      this.saleModel = this.setModelSaleComputed

                      // Hide loader
                      this.hideLoader()
                    })
              })
        }
      } else {
        // Handle list by day for create
        let listForCreate = []
        this.listByDay.forEach((sale) => {
          if(sale.userWithVat !== null || sale.userWithoutVat !== null) {
            listForCreate.push(sale)
          }
        })

        // Send create
        this.$store.dispatch('createOrUpdateSales', {
          centerId: this.$store.getters.getChannel.center.id,
          data: listForCreate,
          shopId: this.SaleModule.shop.id,
          userId: this.AuthModule.currentUser.id
        })
          .then(() => {
            this.saleError.isError = false
            this.saleError.errorMessage = null

            // Fetch Sales
            this.$store.dispatch('fetchSales', {
              centerId: this.$store.getters.getChannel.center.id,
              month : this.period.month,
              year : this.period.year,
              shopId : this.$route.params.id
            })
              .then(() => {
                // ListByDay
                this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

                // Hide loader
                this.hideLoader()
              })
          })
          .catch((error) => {
            this.saleError.isError = true
            this.saleError.errorMessage = error

            this.hideLoader()
          })
      }
    },
    /*--------------- MAIL ----------------*/
    /**
     * Send email on click ask for help
     */
    sendEmailAskForHelp() {
      this.$store.dispatch('sendEmailAskForHelp', {
        centerId: this.$store.getters.getChannel.center.id,
        shopId : this.$route.params.id,
        userId : this.AuthModule.currentUser.id,
        phone: this.askForHelp.form.phone,
        email: this.askForHelp.form.email
      })
        .then(() => {
          this.askForHelp.isEmailSend = true
          this.askForHelp.clicked = false

          // Hide the modal manually
          this.$nextTick(() => {
            this.$bvModal.hide('modal-form-ask-for-help')
          })
        })
    },
    /**
     * Reset for ask for help
     */
    resetEmailSend()
    {
      this.askForHelp.isEmailSend = false
      this.askForHelp.form = {
        phone: null,
        email: null,
        error: null
      }
    },
    /*--------------- MODAL ----------------*/
    /**
     * Reset modal data
     */
    resetModal()
    {
      this.askForHelp.clicked = false
    },
    /**
     * Click on send modal ask for help
     */
    handleModalAskForHelp(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()

      // Send email
      if(this.askForHelp.form.phone === null && this.askForHelp.form.email === null) {
        this.askForHelp.form.error = 'Merci de renseigner au moins le téléphone OU l\'e-mail'
      } else {
        this.sendEmailAskForHelp()
        this.askForHelp.form.error = null
      }
    },
    /*--------------- USER CAN EDIT ----------------*/
    checkUserCanEdit(elem) {
      let isEdit = false

      if(this.$store.getters.userIsAdmin) {
        isEdit = true
      } else if(this.$store.getters.userIsShop) {
        if(elem.bgColor === 'bg-warning') {
          isEdit = false
        } else {
          if(this.SaleModule.shop.isMonth) {
            isEdit = (this.SaleModule.sales.length === 0)
          } else {
            isEdit = this.getLastDayInMonthPeriod !== this.$store.getters.getCountSales
          }
        }
      }

      return isEdit
    }
  },
  watch: {
    countFillCardSales: function(newValue) {
      this.countFillCardSales = newValue
    },
    setModelSaleComputed: function(newValue)
    {
      this.saleModel = newValue
    },
  },
  computed: {
    getLastDayInMonthPeriod()
    {
      let constructDate = this.period.year + '-' + this.period.month + '-01'
      let dateObj = new Date(constructDate)
      let date = moment(dateObj).format("YYYY-MM-DD")

      return parseInt(moment(date).daysInMonth())
    },
    /**
     * set computed sale model
     * @returns {{date: (*|null), userWithoutVat: (null|*|null), totalWithVat: (null|*|null), vatRate: (null|*|null), id: (*|null), totalWithoutVat: (null|*|null), userWithVat: (null|*|null)}}
     */
    setModelSaleComputed()
    {
      // current date for late sale
      let dateObj = new Date()
      let currentDate = moment(dateObj).format('YYYY-MM-DD')

      return {
        id: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].id : null,
        date: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].date : this.getDatePeriod('YYYY-MM-DD'),
        totalWithVat: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].totalWithVat : null,
        totalWithoutVat: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].totalWithoutVat : null,
        userWithVat: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].userWithVat.id : null,
        userWithoutVat: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].userWithoutVat.id : null,
        vatRate: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].vatRate : null,
        bgColor: (this.SaleModule.sales[0]) ? this.handleColorInput(this.SaleModule.sales[0], true) : (currentDate > this.period.dateLimit) ? 'bg-danger' : '',
      }
    },
    userCanEditComputed()
    {
      if(this.$store.getters.userIsAdmin) {
        return true
      } else {
        return false
      }
    },
    ...mapState(['ChannelModule']),
    ...mapState(['AuthModule']),
    ...mapState(['SaleModule']),
  },
  components: {
    Datepicker: () => import('vuejs-datepicker'),
  },
}
</script>
