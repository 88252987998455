import AxiosService from '@/services/AxiosService'

export default {
  /**
   * Get list of shop event
   *
   * @returns {*}
   */
  getShopEvents(model) {
    return AxiosService.callApi().get('/' + model.centerId + '/shop/events/list/' + model.shopId)
  },
  /**
   * Get shop event by id
   *
   * @param model
   * @returns {*}
   */
  getShopEvent(model) {
    return AxiosService.callApi().get('/' + model.centerId + '/shop/events/' + model.id)
  },
  /**
   * Create shop event
   *
   * @param model
   * @returns {*}
   */
  create(model) {
    return AxiosService.callApi().post('/' + model.centerId + '/shop/events', model.shopEvent)
  },
  /**
   * Edit shop event
   *
   * @param model
   * @returns {*}
   */
  edit(model) {
    return AxiosService.callApi().put('/' + model.centerId + '/shop/events/' + model.id, model.shopEvent)
  },
  /**
   * Delete shop event
   *
   * @param model
   */
  delete(model) {
    return AxiosService.callApi().delete('/' + model.centerId + '/shop/events/' + model.id)
  }
}
