import ShopEventService from '@/services/ShopEventService'

export default {
  state: {
    shopEvent: {},
    shopEvents: [],
  },
  mutations: {
    ADD_SHOP_EVENT(state, shopEvent) {
      state.shopEvents.push(shopEvent)
    },
    SET_SHOP_EVENTS(state, shopEvents) {
      state.shopEvents = shopEvents
    },
    SET_SHOP_EVENT(state, shopEvent) {
      state.shopEvent = shopEvent
    },
    DELETE_SHOP_EVENT(state, index) {
      state.shopEvents.splice(state.shopEvents.indexOf(index), 1)
    }
  },
  actions: {
    /**
     * Fetch in state list of shop events
     *
     * @param commit
     * @param model
     */
    fetchShopEvents({ commit }, model) {
      return ShopEventService.getShopEvents(model)
        .then(response => {
          commit('SET_SHOP_EVENTS', response.data)
        })
        .catch(error => {
          console.log('There was an error on shop event list: ', error)
        })
    },
    /**
     * Fetch in state a shop event
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchShopEvent({ commit }, id)
    {
      return ShopEventService.getShopEvent(id)
        .then(response => {
          commit('SET_SHOP_EVENT', response.data)
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          console.log('There was an error on shop event : ', error)
        })
    },
    /**
     * Create shop event
     *
     * @param commit
     * @param model
     */
    createShopEvent({ commit }, model)
    {
      return ShopEventService.create(model)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_SHOP_EVENT', model.shopEvent)
          return Promise.resolve()
        })
    },
    /**
     * Update shop event
     *
     * @param commit
     * @param model
     * @returns {*}
     */
    updateShopEvent({ commit }, model)
    {
      return ShopEventService.edit(model)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_SHOP_EVENT', response.data)
        })
    },
    /**
     * Delete shop event
     *
     * @param commit
     * @param model
     * @returns {*}
     */
    deleteShopEvent({ commit } , model)
    {
      return ShopEventService.delete(model)
        .then(() => {
          commit('DELETE_SHOP_EVENT', model.index)
        })
    }
  },
  getters: {
    /**
     * Get shopEvent by id into list load by fecthShopEvents
     *
     * @param state
     * @returns {function(*): * | undefined}
     */
    getShopEventById: state => id => {
      return state.shopEvents.find(shopEvent => shopEvent.id === id)
    }
  }
}
