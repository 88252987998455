<template>
  <div id="page-company-create">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-4">
      <h5>{{ dataPage.title }}</h5>
      <div>
        <router-link
            class="btn btn-outline-primary mt-3 mt-md-0"
            :to="{ name: 'company-list' }"
        >
          retour
        </router-link>
      </div>
    </div>

    {{ /* Form component */ }}
    <FormCrudCompany
        :company="company"
        :actionForm="'create'"
    />
  </div>
</template>

<script>

export default {
  data() {
    return {
      dataPage: {
        title: 'Créer une entreprise'
      },
      company: {}
    }
  },
  components : {
    FormCrudCompany: () => import('@/components/pages/company/FormCrudCompany')
  }
}
</script>

<style scoped>

</style>
