<template>
  <div class="page-user-show">
    <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
      <div class="text-center">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
    <div v-else>
      {{ /* Heading page */ }}
      <div class="header-pages-block d-md-flex justify-content-between mb-5">
        <div>
          <h5>{{ dataPage.title }} <strong>{{ UserModule.user.firstname }} {{ UserModule.user.lastname }} </strong></h5>
          <h5 v-if="UserModule.user.shop">Commerce : <strong>{{ UserModule.user.shop.name }}</strong></h5>
        </div>

        <div>
          <router-link
              class="btn btn-outline-primary mt-2 mt-md-0"
              :to="{ name: 'user-list' }"
          >
            retour
          </router-link>
        </div>
      </div>

      <h3>Informations globales</h3>
      <hr />

      <div class="d-flex mb-3">
        <span><strong>Nom de l'utilisateur</strong> : </span>
        <span class="ml-2">{{ UserModule.user.username }}</span>
      </div>

      <div class="d-flex mb-3">
        <span><strong>Email</strong> : </span>
        <span class="ml-2">{{ UserModule.user.email }}</span>
      </div>

      <div class="d-flex mb-3">
        <span><strong>Prénom</strong> : </span>
        <span class="ml-2">{{ UserModule.user.firstname }}</span>
      </div>

      <div class="d-flex mb-3">
        <span><strong>Nom</strong> : </span>
        <span class="ml-2">{{ UserModule.user.lastname }}</span>
      </div>

      <div class="d-flex mb-3">
        <span><strong>Téléphone</strong> : </span>
        <span class="ml-2">{{ UserModule.user.phone }}</span>
      </div>

      <div class="d-flex mb-3">
        <span><strong>Rôle</strong> : </span>
        <span class="ml-2">{{ UserModule.user.roles }}</span>
      </div>

      <h3 class="mt-4 mb-4">Config(s)</h3>
      <hr />

      <div class="d-flex mb-3">
        <span><strong>Utilisateur actif </strong> : </span>
        <span class="ml-2">{{ isActive }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      isActive: '',
      dataPage: {
        title: 'Détails de l\'utilisateur : '
      },
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  async created() {
    this.showLoader()

    let model = {
      centerId: this.$store.getters.getChannel.center.id,
      userId: this.$route.params.id
    }

    await this.$store.dispatch('fetchUser', model)
      .then(() => {
        setTimeout(function () { this.hideLoader() }.bind(this), 250)
      })
  },
  computed: {
    ...mapState(['ChannelModule']),
    ...mapState(['UserModule'])
  },
  mounted() {
    this.isActive = (this.UserModule.user.isActive) ? "Oui" : "Non"
  },
  components: {
  }
}
</script>
