<template>
  <div id="page-user-create">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-md-4">
      <h5 class="">{{ dataPage.title }}</h5>
      <div>
        <router-link
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'user-list' }"
        >
          retour
        </router-link>
      </div>
    </div>

    {{ /* Form component */ }}
    <FormCrudUser
        :user="user"
        :actionForm="'create'"
        :selected-shop="0"
        from="crud"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataPage: {
        title: 'Créer un utilisateur'
      },
      user: {
        isActive: true
      }
    }
  },
  components : {
    FormCrudUser: () => import('@/components/pages/user/FormCrudUser')
  }
}
</script>

<style scoped>

</style>
