import AxiosService from '@/services/AxiosService'

export default {
  /**
   * Get list of event
   *
   * @returns {*}
   */
  getEvents() {
    return AxiosService.callApi().get('/events')
  },
}
